
.select-design{
  opacity: 0; height: 40px;
  &.select-lg{ 
    & +.select2-container .select2-selection--single{ 
      height: 46px;
      .select2-selection__rendered{ line-height: 46px; font-size: 15px;}
    }
  }
  &.select-xl{ 
    & +.select2-container .select2-selection--single{ 
      height: 54px;
      .select2-selection__rendered{ line-height: 54px; font-size: 15px;}
    }
  }
}

.select2-container{
  height: 40px;
}
.select2-container--default{
  .select2-selection--single{
    height: 40px;
    .select2-selection__rendered{
      line-height: 1; padding: 12px 30px 12px 10px; color: #4d4d4d; letter-spacing: -0.16px;
    }
    .select2-selection__arrow{
      b{ border-color: transparent; border-style: none; border-width: 0; background-image: url('../images/icon-dropdown.svg'); width: 24px; height: 24px; margin-left: -20px; margin-top: -5px;}
    }
  }
  .select2-results__option--highlighted.select2-results__option--selectable{
    color: #000; background-color: #fff;
  }
  .select2-results__option--selected{
    color: #000; background-color: #fff; background-image: url('../images/icon-check.svg'); background-size: 16px 16px;
    background-repeat: no-repeat; background-position: 80px 5px;
  }
} 
.select2-results__option{
  color: rgba(0, 0, 0, 0.4); font-size: 14px; line-height: 1.71; padding: 2px 25px 2px 10px;
}