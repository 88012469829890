// -------------------------------- ESG Story start

.brand-esg-head {
  padding-top: 150px;
  .brand-esg-title {
    font-family: "Brand New Hyosung OTF";
    font-weight: 700;
    font-size: 48px;
    line-height: 57px;
    text-align: center;
    color: #ffffff;
  }
  @include media-breakpoint-down(lg) {
    padding-top: 80px;
    .brand-esg-title {
      font-size: 28px;
      line-height: 36px;
    }
  }
}
@include media-breakpoint-up(lg) {
  .esg-section {
    &.story {
      height: 1335px;
      background-color: $dark-gray-2;
      padding: 130px 0 200px 0;
      .esg-story-wrap {
        position: relative;
        .swiper-horizontal > .swiper-scrollbar,
        .swiper-scrollbar.swiper-scrollbar-horizontal {
          width: 20%;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
          height: 2px;
          border-radius: 0;
        }
        .swiper-scrollbar {
          background: #e9e9e9;
        }
        .swiper-scrollbar-drag {
          background: $primary;
        }
      }
      .esg-story {
        position: relative;
        margin-top: 40px;
        padding-bottom: 130px;
        .swiper-wrapper {
          gap: 20px;
        }
        .swiper-slide {
          width: auto !important;
          .slide-contents-wrap {
            display: block;
            width: 100%;
            height: 100%;
            &:hover {
              .btn.round-btn.plus {
                color: $white !important;
                background-color: $primary !important;
                border-color: $primary !important;
                .icon-plus {
                  background-image: url("../images/icon-w-plus.svg");
                }
              }
            }
          }
          .img-box {
            transform: scale(1);
            transition: 0.5s;
            &.txt {
              position: relative;
            }
            .desc-box {
              position: absolute;
              bottom: 38px;
              left: 50px;
              .date {
                font-weight: 500;
                font-size: 12px;
                line-height: 14px;
                color: $white;
              }
              .title {
                font-weight: 600;
                font-size: 32px;
                line-height: 42px;
                color: $white;
                margin-top: 20px;
                @include ellipsis(2);
                width: 80%;
                @include media-breakpoint-down(lg){
                  width: 100%;
                }
              }
            }
            .btn {
              position: absolute;
              right: 36px;
              bottom: 36px;
            }
          }
          &.active {
            .img-box {
              transform: scale(0.95);
              transition: 0.5s;
            }
          }
        }
      }
    }
  }
  .sustainability {
    background-color: #f5f5f5;
    padding-bottom: 180px;
    .section-title {
      font-weight: 700;
      font-size: 66px;
      line-height: 72px;
      color: $white;
      margin-bottom: 84px;
      font-family: 'Brand New Hyosung OTF';

    }
    .esg-story-content {
      margin-top: -350px;
    }
    .esg-story-list {
      li {
        position: relative;
        padding-bottom: 40px;
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 80px;
        }
        .img {
        }
        .desc {
          width: 66.5%;
          background-color: $white;
          padding: 40px 60px;
          position: absolute;
          bottom: 0;
          right: 0;
          .date {
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            color: #000000;
          }
          .title {
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            color: $dark-gray-1;
            margin-top: 1.2rem;
            @include ellipsis(1);
          }
          .txt {
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            color: rgba(0, 0, 0, 0.7);
            margin-top: 2rem;
            @include ellipsis(3);
          }
        }
      }
    }
  }
  .esg-stroy-detail {
    padding: 130px 0 185px;
    .content {
      margin-bottom: 140px;
    }
    .history-back {
      color: $primary;
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 2.25rem;
      display: flex;
      align-items: center;
      i {
        margin-right: 8px;
      }
    }
    .title {
      font-weight: 600;
      font-size: 40px;
      line-height: 54px;
      color: $dark-gray-1;
    }
    .image-box {
      margin-top: 40px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .content-title {
      font-weight: 700;
      font-size: 32px;
      line-height: 32px;
      color: $dark-gray-3;
    }
    .desc {
      font-size: 18px;
      line-height: 32px;
      color: $dark-gray-3;
      margin-top: 30px;
      .desc-emphasis {
        font-weight: 600;
      }
    }
    .product-list {
      margin: 80px 0;
    }
    .product-list-box {
      padding: 40px 60px;
      background-color: $light-gray-3;
      border-bottom: 1px solid $light-gray-2;
      &:last-child {
        border-bottom: 0;
      }
      .list-box-title {
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        color: $dark-gray-3;
      }
      .list-box-desc {
        font-size: 18px;
        line-height: 32px;
        color: $dark-gray-3;
        margin-top: 12px;
      }
    }
  }
  .esg-detail-footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;
    border-top: 1px solid $light-gray-1;
    .d-flex {
      gap: 10px;
    }
  }
}
@include media-breakpoint-down(lg) {
  .esg-section.story {
    &.story {
      background-color: $dark-gray-2;
      padding: 50px 0 70px 0;
      position: relative;
      .esg-story-wrap {
        .swiper-horizontal > .swiper-scrollbar,
        .swiper-scrollbar.swiper-scrollbar-horizontal {
          width: 50%;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
          height: 4px;
          border-radius: 0;
        }
        .swiper-scrollbar {
          background: #444444;
        }
        .swiper-scrollbar-drag {
          background: $primary;
        }
      }
      .esg-story {
        position: relative;
        margin-top: 40px;
        padding-bottom: 60px;
        .swiper-slide {
          .img-box {
            &.txt {
              position: relative;
            }
            .desc-box {
              display: flex;
              flex-direction: column;
              padding: 0 18px;
              .date {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: $gray-1;
                order: 2;
                margin-top: 1rem;
              }
              .title {
                font-weight: 700;
                font-size: 22px;
                line-height: 30px;
                color: $white;
                margin-top: 1.5rem;
                order: 1;
                @include ellipsis(2);
              }
            }
            .btn {
              display: none;
            }
          }
        }
      }
    }
  }
  .sustainability {
    background-color: #f5f5f5;
    padding-bottom: 180px;
    .section-title {
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      color: $dark-gray-1;
      margin-bottom: 50px;
    }
    .esg-story-content {
      margin-top: 50px;
    }
    .esg-story-list {
      li {
        margin-bottom: 30px;
        .desc {
          width: 100%;
          background-color: $white;
          padding: 20px 20px 45px;
          .date {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: $dark-gray-2;
          }
          .title {
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
            color: $dark-gray-2;
            margin-top: 1.2rem;
            @include ellipsis(2);
          }
          .txt {
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            color: $dark-gray-3;
            margin-top: 1.5rem;
            @include ellipsis(4);
          }
        }
      }
    }
  }
  .esg-stroy-detail {
    padding: 100px 0 200px;
    .content {
      margin-bottom: 70px;
    }
    .history-back {
      color: $primary;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 1.25rem;
    }
    .title {
      font-weight: 700;
      font-size: 26px;
      line-height: 36px;
      color: $dark-gray-1;
    }
    .image-box {
      margin-top: 30px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .content-title {
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      color: $dark-gray-3;
    }
    .desc {
      font-size: 16px;
      line-height: 26px;
      color: $dark-gray-3;
      margin-top: 20px;
    }
    .product-list {
      margin: 30px 0;
    }
    .product-list-box {
      padding: 30px 20px;
      background-color: $light-gray-3;
      border-bottom: 1px solid $light-gray-2;
      &:last-child {
        border-bottom: 0;
      }
      .list-box-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: $dark-gray-3;
      }
      .list-box-desc {
        font-size: 16px;
        line-height: 26px;
        color: $dark-gray-3;
        margin-top: 10px;
      }
    }
  }
  .esg-detail-footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;
    border-top: 1px solid $light-gray-1;
    .d-flex {
      gap: 10px;
    }
  }
}
// -------------------------------- ESG Story end

// -------------------------------- ESG Activity start

.esg-section {
  background-color: #202020;
  padding: 130px 0 125px 0;
  .esg-activity-wrap {
    width: 70vw;
    margin-left: auto;
  }
  @include media-breakpoint-down(lg) {
    padding: 50px 0 65px 0;
    .esg-activity-wrap {
      width: 100%;
      padding: 0 18px;
    }
  }
  .esg-activity {
    padding-bottom: 18rem;
    margin-top: 80px;
    .desc-box {
      position: absolute;
      right: 15%;
      width: 47%;
      top: 21rem;
    }
    .title {
      font-weight: 700;
      line-height: 50px;
      font-size: 42px;
      color: $white;
    }
    .txt {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: rgba(255, 255, 255, 0.8);
      margin-top: 30px;
    }
    .more {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: rgba(255, 255, 255, 0.5);
      margin-top: 26px;
      i {
        margin-left: 5px;
      }
    }
    .counter {
      position: absolute;
      bottom: -3rem;
      span {
        font-size: 13px;
        line-height: 16px;
        color: rgba(255, 255, 255, 0.5);
      }
      .current-slide {
        color: rgba(255, 255, 255, 0.8);
      }
    }
    @include media-breakpoint-down(xxl) {
      .desc-box {
        right: 10%;
        width: 70%;
        top: 12rem;
      }
      .title {
        font-size: 22px;
        line-height: 30px;
        margin-top: 34px;
      }
      .txt {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: rgba(255, 255, 255, 0.8);
        margin-top: 30px;
      }
      .more {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: rgba(255, 255, 255, 0.5);
        margin-top: 26px;
        i {
          margin-left: 5px;
        }
      }
    }
    @include media-breakpoint-down(lg) {
      margin-top: 40px;
      padding-bottom: 5rem;
      .img-box {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .desc-box {
        position: static;
      }
      .title {
        line-height: 30px;
      }
      .txt {
        display: none;
      }
      .more {
        display: none;
      }
      .counter {
        bottom: -2.2rem;
        span {
          font-size: 14px;
        }
      }
    }
  }
}
.activity {
  padding: 140px 0 180px;
  .section-title {
    font-weight: 700;
    font-size: 66px;
    line-height: 72px;
    color: $dark-gray-1;
    margin-bottom: 85px;
  }
  .esg-activity-content {
    margin-bottom: 80px;
  }
  .esg-activity-list {
    li {
      padding: 40px 40px 60px;
      border-bottom: 1px solid $light-gray-2;
      &:first-child {
        border-top: 2px solid $dark-gray-1;
      }
      .mo-source {
        display: none;
      }
      .pc-source {
        display: flex;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: $dark-gray-3;
        gap: 15px;
        .source-tit {
          color: #f44242;
        }
        .source-desc {
          position: relative;
          &::before {
            content: "";
            position: absolute;
            width: 2px;
            height: 80%;
            left: 0;
            background-color: $dark-gray-3;
            left: -7px;
            top: 2px;
          }
        }
      }
    }
    a {
      display: flex;
      justify-content: space-between;
      margin-top: 2rem;

      .date {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: $dark-gray-3;
        margin-top: 10px;
      }
      .title {
        font-weight: 700;
        font-size: 32px;
        line-height: 38px;
        color: $dark-gray-3;
        margin-top: 30px;
      }
      .txt {
        width: 60%;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: $gray-3;
        margin-top: 20px;
        height: 45px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    padding: 50px 0 200px;
    .section-title {
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 20px;
    }
    .esg-activity-content {
      margin-bottom: 48px;
    }
    .esg-activity-list {
      li {
        padding: 20px 0 34px;
        border-bottom: 0;
        .pc-source {
          display: none;
        }
        .mo-source {
          display: flex;
          font-weight: 700;
          font-size: 14px;
          line-height: 19px;
          color: $dark-gray-3;
          gap: 15px;
          margin-top: 20px;
          .source-tit {
            color: #f44242;
          }
          .source-desc {
            position: relative;
            &::before {
              content: "";
              position: absolute;
              width: 2px;
              height: 80%;
              left: 0;
              background-color: $dark-gray-3;
              left: -7px;
              top: 2px;
            }
          }
        }
      }
      a {
        flex-direction: column;
        margin: 0;
        .desc {
          display: flex;
          flex-direction: column;
          order: 2;
          padding: 0 20px;
        }
        .date {
          margin-top: 20px;
          order: 2;
        }
        .title {
          font-size: 24px;
          line-height: 30px;
          margin-top: 14px;
          order: 1;
        }
        .txt {
          width: 100%;
          font-size: 16px;
          line-height: 26px;
          color: $gray-3;
          margin-top: 24px;
          order: 3;
          overflow: inherit;
          text-overflow: inherit;
          display: block;
        }

        .img {
          order: 1;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}

// -------------------------------- ESG Activity end

// ----- ESG 경영 start
.management-wrap {
  width: 100%;
  height: 1985px;
  background-image: url("../images/esg-management-bg.png");
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(
      180deg,
      #161618 29.57%,
      rgba(29, 29, 30, 0.8) 52.44%,
      rgba(44, 44, 44, 0) 97.63%
    );
    z-index: -1;
  }
  @include media-breakpoint-down(lg) {
    height: 1416px;
    background-image: url("../images/esg-management-bg-mo.png");
  }
}
.management-section-1 {
  .esg-management-head {
    margin-top: 280px;
    .management-head-title {
      font-weight: 500;
      font-size: 28px;
      line-height: 38px;
      text-align: center;
      color: $red;
      font-family: 'Brand New Hyosung OTF';
    }
    .management-head-desc {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 70px;
    }
  }
  .management-visual {
    padding: 290px 0 320px;
    .management-visual-title {
      font-weight: 700;
      font-size: 48px;
      line-height: 57px;
      color: #ffffff;
      font-family: 'Brand New Hyosung OTF';
    }
    .management-visual-desc {
      margin-top: 200px;
      p {
        font-size: 16px;
        line-height: 28px;
        color: #ffffff;
        margin-bottom: 14px;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .esg-management-head {
      margin-top: 80px;
      .management-head-title {
        font-weight: 700;
        font-size: 12px;
        line-height: 22px;
      }
      .management-head-desc {
        margin-top: 20px;
        img {
          width: 45%;
        }
      }
    }
    .management-visual {
      padding: 100px 0 160px;
      .management-visual-title {
        font-size: 28px;
        line-height: 36px;
      }
      .management-visual-desc {
        margin-top: 40px;
        p {
          line-height: 26px;
          margin-bottom: 30px;
          word-break: break-all;
        }
      }
    }
  }
}
.management-section-2 {
  padding: 160px 0 100px;
  .synopsis-wrap {
    position: relative;
    z-index: 1;
    margin-bottom: 205px;
    &:last-child {
      margin-bottom: 0;
    }
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 235px;
      background-image: url("../images/box-arrow.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      top: 155px;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
    }
    .synopsis-box {
      padding: 60px 30px;
      border: 1px dashed #a8afba;
      text-align: center;
      background-color: #fff;

      .synopsis-box-title {
        font-weight: 700;
        font-size: 28px;
        line-height: 38px;
        color: #161618;
      }
      .synopsis-box-desc {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #444444;
        margin-top: 30px;

        max-width: 1028px;
        margin-left: auto;
        margin-right: auto;
        @include media-breakpoint-down(lg){
          width: 100%;
        }
      }
    }
    &.none-img {
      &::before {
        content: none;
      }
      .synopsis-box {
        border: 1px solid #444;
        background-color: #161618;
        box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
        .synopsis-box-title {
          color: #fff;
        }
        .synopsis-box-desc {
          color: #fff;
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    padding: 100px 0 100px;
    .synopsis-wrap {
      margin-bottom: 150px;
      &::before {
        width: calc(100% + 36px);
        top: 60%;
      }
      &.second {
        &::before {
          top: 125px;
        }
      }
      .synopsis-box {
        padding: 30px 18px;

        .synopsis-box-title {
          font-size: 22px;
          line-height: 32px;
        }
        .synopsis-box-desc {
          font-size: 14px;
          line-height: 22px;
        }
      }
      &.none-img {
        &::before {
          content: none;
        }
        .synopsis-box {
          border: 1px solid #444;
          background-color: #161618;
          box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
          .synopsis-box-title {
            color: #fff;
          }
          .synopsis-box-desc {
            color: #fff;
          }
        }
      }
    }
  }
}
.management-section-3 {
  padding: 180px 0 120px;
  @include media-breakpoint-down(lg) {
    padding: 100px 0 120px;
  }
  @at-root {
    .management-section-title {
      font-weight: 700;
      font-size: 48px;
      line-height: 57px;
      text-align: center;
      color: #161618;
      margin-bottom: 100px;
      font-family: "Brand New Hyosung OTF";
      @include media-breakpoint-down(lg) {
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 40px;
      }
    }
    .management-section-sub {
      font-family: "Brand New Hyosung OTF";
      font-weight: 700;
      font-size: 34px;
      line-height: 41px;
      color: #161618;
      margin-top: 40px;
      text-align: center;
    }
    .management-section-subtitle {
      text-align: center;
      p {
        font-size: 16px;
        line-height: 24px;
        color: #444444;
        max-width: 1028px;
        margin-left: auto;
        margin-right: auto;
      }
      strong {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #444444;
        margin-top: 14px;
        display: block;
      }
    }
    @include media-breakpoint-down(lg) {
      .management-section-sub {
        font-family: "Brand New Hyosung OTF";
        font-weight: 700;
        font-size: 28px;
        line-height: 36px;
        color: #161618;
        text-align: center;
        margin-top: 0;
        &.case-1 {
          font-size: 22px;
          line-height: 32px;
        }
      }
      .management-section-subtitle {
        text-align: left;
        p {
          line-height: 26px;
        }
        strong {
          line-height: 26px;
          margin-top: 30px;
        }
      }
    }
    .management-card-list {
      > div {
        margin-bottom: 60px;
      }
      .management-card {
        padding: 100px 11.5% 80px;
        min-height: 724px;
        background-color: #fbfbfb;
        border: 1px solid #dddddd;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .management-card-title {
          font-weight: 700;
          font-size: 24px;
          line-height: 35px;
          color: #161618;
        }
        .management-card-desc {
          margin-top: 32px;
          p {
            font-size: 16px;
            line-height: 24px;
            color: #444444;
            margin-bottom: 14px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .img {
          display: flex;
          gap: 80px;
        }
      }
      @include media-breakpoint-down(lg) {
        > div {
          margin-bottom: 20px;
        }
        .management-card {
          padding: 50px 40px;
          min-height: 459px;
          justify-content: inherit;
          .management-card-title {
            font-size: 20px;
            line-height: 26px;
          }
          .management-card-desc {
            margin-top: 20px;
            p {
              font-size: 14px;
              line-height: 22px;
            }
          }
          .img {
            margin-top: 60px;
          }
        }
      }
    }
  }
}
.management-section-4 {
  padding: 160px 0 200px;
  background-color: #006a4c;
  @include media-breakpoint-down(lg) {
    padding: 100px 0 160px;
  }
  .management-section-title {
    color: #fff;
  }
  .management-section-subtitle {
    p {
      color: #fff;
    }
  }
  @at-root {
    .brand-design {
      display: flex;
      margin-top: 100px;
      .design-logo {
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 20px;
      }
      .design-desc {
        width: 60%;
        background-color: #fbfbfb;
        border-radius: 20px;
        ul {
          padding: 93px 0 93px 100px;
          li {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #006a4c;
            padding-bottom: 20px;
            &:last-child {
              padding-bottom: 0;
            }
            span {
              font-size: 10px;
              vertical-align: middle;
              font-weight: 700;
            }
          }
        }
        &.alliance {
          padding: 80px;
        }
        p {
          color: #444;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 14px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      @include media-breakpoint-down(lg) {
        flex-direction: column;
        margin-top: 60px;
        .design-logo {
          width: 100%;
          padding: 60px 90px;
        }
        .design-desc {
          width: 100%;
          ul {
            padding: 50px 40px;
            li {
              line-height: 26px;
              padding-bottom: 16px;
              &:last-child {
                padding-bottom: 0;
              }
            }
          }
          &.alliance {
            padding: 50px 40px;
          }
          p {
            color: #444;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 14px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .brand-design-comment {
      margin-top: 80px;
      p {
        font-size: 27px;
        line-height: 120%;
        text-align: center;
        color: #ffffff;
        strong {
          font-weight: 700;
        }
      }
      .purpose-title {
        font-weight: 700;
        font-size: 34px;
        line-height: 41px;
        color: #ffffff;
      }
      .purpose-translation {
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        color: #dddddd;
      }
      .purpose-desc {
        font-size: 16px;
        line-height: 24px;
        color: #dddddd;
        margin-top: 32px;
        max-width: 1065px;
        margin-left: auto;
        margin-right: auto;
      }
      @include media-breakpoint-down(lg) {
        p {
          font-size: 20px;
          line-height: 26px;
        }
        .purpose-title {
          font-size: 22px;
          line-height: 32px;
        }
        .purpose-translation {
          font-size: 14px;
          line-height: 22px;
        }
        .purpose-desc {
          line-height: 26px;
          margin-top: 20px;
          text-align: left;
        }
      }
    }
  }
}
.management-section-5 {
  padding: 160px 0 200px;
  @include media-breakpoint-down(lg) {
    padding: 100px 0 160px;
  }
}

.management-section-6 {
  padding: 160px 0 120px;
  background-color: #fbfbfb;
  @include media-breakpoint-down(lg) {
    padding: 100px 0;
  }
  .brand-development-list {
    display: flex;
    flex-flow: wrap;
    gap: 40px;
    justify-content: center;
    .brand-development-content {
      width: 235px;
      height: 235px;
      border-radius: 50%;
      background: #f9f9f9;
      box-shadow: 0px 4px 10px rgba(99, 103, 107, 0.25);
      display: flex;
      align-items: center;
      justify-content: center;
      &.active {
        border: 3px dashed $red;
      }
    }
  }
  @at-root {
    .brand-development-txt {
      margin: 60px 0 80px;
      &.case-1 {
        max-width: 740px;
        margin-left: auto;
        margin-right: auto;
      }
      &.case-2 {
        max-width: 1070px;
        margin-left: auto;
        margin-right: auto;
      }
      &.case-3 {
        max-width: 1130px;
        margin-left: auto;
        margin-right: auto;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #444444;
        margin-bottom: 14px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      @include media-breakpoint-down(lg) {
        margin: 60px 0;
        p {
          line-height: 26px;
          margin-bottom: 30px;
          text-align: left;
        }
      }
    }
  }
  .brand-company-list {
    display: flex;
    flex-flow: wrap;
    gap: 1.4%;
    justify-content: space-between;
    .brand-company-content {
      width: calc(100% / 6 - 1.4%);
      .company-content-head {
        background-color: $dark-gray-1;
        font-weight: 700;
        font-size: 18px;
        line-height: 32px;
        text-align: center;
        color: #fff;
        padding: 11px 0;
      }
      .company-content-box {
        height: 132px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px 10%;
        margin-top: 20px;
        position: relative;
        border: 1px solid #ddd;
        &::before {
          content: "";
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          width: 1px;
          height: 20px;
          top: -20px;
          background-color: #ddd;
        }
        p {
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #161618;
          white-space: normal;
        }
      }
      &.active {
        .company-content-head {
          background-color: $red;
        }
        .company-content-box {
          p {
            font-weight: bold;
          }
        }
      }
    }
    @include media-breakpoint-down(lg) {
      gap: 13px;
      width: 1330px;
      height: 100%;
      white-space: nowrap;
      overflow-x: auto;
      flex-wrap: nowrap;
      justify-content: flex-start;
      padding: 0 18px;
      .brand-company-content {
        width: calc(33.3% - 9px);
        .company-content-head {
          font-size: 18px;
          line-height: 32px;
          padding: 16px 10%;
        }
        .company-content-box {
          // height: 115px;
          min-height: 135px;
          padding: 20px 10%;
          li {
            font-size: 12px;
            line-height: 22px;
            padding-bottom: 4px;
            &:last-child {
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }
}
.purpose-wrap {
  display: flex;
  gap: 60px;
  margin-top: 80px;
  @include media-breakpoint-down(lg) {
    width: 1330px;
    height: 100%;
    white-space: nowrap;
    overflow-x: auto;
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding: 0 18px;
  }
}
.purpose-content {
  width: calc(33.3% - 40px);
  display: flex;
  flex-direction: column;
  align-items: center;
  .purpose-content-head {
    width: 100%;
    min-height: 168px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #444444;
    margin-bottom: 20px;
    white-space: normal;
    padding: 0 10%;
  }
  .purpose-content-body {
    width: 100%;
    background-color: #f5f5f5;
    border: 1px dashed #006a4c;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
    padding: 34px 0;
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    color: #333333;
    margin-top: 30px;
  }
}
.management-section-8 {
  padding: 160px 0 220px;
  .management-section-title {
    margin-bottom: 40px;
  }
  .design-logo {
    border: 1px solid #ddd;
    margin-right: -1px;
  }
  .alliance {
    border: 1px solid #ddd;
  }
  @include media-breakpoint-down(lg) {
    padding: 100px 0 140px;
    .management-section-title {
      margin-bottom: 0;
    }
  }
}
.guardian-wrap {
  margin-top: 120px;
  .guardian-list {
    display: flex;
    gap: 100px;
    margin-top: 80px;
  }
  .guardian-content {
    width: calc(50% - 50px);
    border: 1px dashed #a8afba;
    padding: 80px 5.23% 70px;
    text-align: center;
    .guardian-content-title {
      font-family: "Brand New Hyosung OTF";
      font-weight: 500;
      font-size: 28px;
      line-height: 38px;
    }
    .guardian-content-desc {
      font-size: 16px;
      line-height: 24px;
      color: #444444;
      margin-top: 20px;
    }
    .guardian-box-list {
      width: 100%;
      height: 680px;
      background: #202022;
      margin-top: 60px;
      .guardian-box {
        height: 25%;
        border-bottom: 1px solid #161618;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        &.half {
          height: 50%;
        }
        &:last-child {
          border-bottom: 0;
        }
        .guardian-box-title {
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          color: #ffffff;
        }
        .guardian-box-desc {
          font-size: 16px;
          line-height: 24px;
          color: #aaaaaa;
          margin-top: 20px;
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    margin-top: 80px;
    .guardian-list {
      gap: 20px;
      flex-direction: column;
      margin-top: 60px;
    }
    .guardian-content {
      width: 100%;
      padding: 50px 30px;
      .guardian-content-title {
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
      }
      .guardian-content-desc {
        font-size: 14px;
        line-height: 22px;
      }
      .guardian-box-list {
        width: 100%;
        height: auto;
        background: #202022;
        margin-top: 40px;
        .guardian-box {
          height: 140px;
          &.half {
            height: 140px;
          }
          .guardian-box-title {
            font-size: 16px;
            line-height: 26px;
          }
          .guardian-box-desc {
            font-size: 14px;
            line-height: 22px;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
// ----- ESG 경영 end

// ----- ESG 브랜드 방향성 start
.directional-section {
  background-color: #161618;
}
.directional-wrap {
  padding-top: 220px;
  .directional-section-title {
    font-weight: 700;
    font-size: 34px;
    line-height: 41px;
    text-align: center;
    color: #ffffff;
  }
  .directional-list {
    display: flex;
    justify-content: center;
    gap: 87px;
    margin-top: 56px;
  }
  .directional-content {
    width: 336px;
    height: 336px;
    border-radius: 50%;
    border: 1px dashed #444444;
    padding: 16px;
    .directional-content-inner {
      width: 100%;
      height: 100%;
      background: #202022;
      box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-items: center;
      border-radius: 50%;
      .directional-content-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 35px;
        color: #ffffff;
      }
      .directional-content-desc {
        font-size: 16px;
        line-height: 24px;
        color: #aaaaaa;
        margin-top: 20px;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    padding-top: 220px;
    .directional-section-title {
      font-weight: 700;
      font-size: 34px;
      line-height: 41px;
      text-align: center;
      color: #ffffff;
    }
    .directional-list {
      flex-direction: column;
      gap: 60px;
      margin-top: 56px;
      align-items: center;
    }
  }
  &.after {
    padding-bottom: 200px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 177px;
      background-image: url("../images/brand-esg-arrow.svg");
      background-repeat: no-repeat;
      background-position: center;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    @include media-breakpoint-down(lg) {
      &::before {
        width: calc(100% + 36px);
      }
    }
    .directional-section-title {
      color: #ff233d;
    }
    .directional-content {
      border: 1px dashed #ff233d;
      .directional-content-inner {
        background-color: #fff;
        box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
        border: 1px solid #ff233d;
        .directional-content-title {
          color: #ff233d;
        }
      }
    }
  }
}
// ----- ESG 브랜드 방향성 end

// ----- ESG 개요 start
.esg-system {
  text-align: center;
  margin-top: 240px;
  .esg-system-title {
    font-weight: 700;
    font-size: 56px;
    line-height: 57px;
    color: #ff233d;
  }
  .esg-system-desc {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #ff233d;
    margin-top: 16px;
  }
  @include media-breakpoint-down(lg) {
    margin-top: 120px;
    .esg-system-title {
      font-size: 44px;
      line-height: 41px;
    }
    .esg-system-desc {
      font-size: 16px;
      line-height: 26px;
      margin-top: 12px;
    }
  }
}
.esg-system-list {
  display: flex;
  gap: 20px;
  margin-top: 68px;
  .esg-system-content {
    width: calc(25% - 12px);
    display: flex;
    flex-direction: column;
    align-items: center;
    .cse-comment {
      font-size: 18px;
      line-height: 26px;
      text-align: center;
      color: #ffffff;
    }
    .icon-esg-b-arrow {
      margin: 20px 0;
    }
    .cse-box {
      text-align: center;
      padding: 46px 0 54px;
      border: 1px solid #444;
      margin-bottom: -1px;
      min-height: 228px;
      .cse-box-title {
        font-size: 24px;
        line-height: 29px;
        color: #ff233d;
      }
      .cse-box-desc {
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        margin-top: 20px;
        white-space: normal;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    width: 1100px;
    height: 100%;
    white-space: nowrap;
    overflow-x: auto;
    gap: 16px;
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding: 0 18px;
    margin-top: 60px;
  }
}
.icon-rotate {
  transform: rotate(-90deg);
}
.cse-process {
  border: 1px dashed #777777;
  padding: 40px 43px;
  border-radius: 271px;
  margin-top: 160px;
  .directional-list {
    gap: 120px;
    margin-top: 0;
  }
  .directional-content {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -94px;
      width: 68px;
      height: 18px;
      background-image: url("../images/cse-arrow.svg");
      background-size: cover;
      background-repeat: no-repeat;
    }
    &:last-child {
      &::after {
        content: none;
      }
    }
    .directional-content-inner {
      .directional-content-title {
        font-size: 16px;
        line-height: 24px;
        margin-top: 28px;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    padding: 14px;
    .directional-content {
      width: 310px;
      height: 310px;
      &::after {
        content: "";
        position: absolute;
        top: inherit;
        bottom: -68px;
        right: inherit;
        left: 50%;
        width: 68px;
        height: 18px;
        background-image: url("../images/cse-arrow.svg");
        background-size: cover;
        background-repeat: no-repeat;
        transform: translateX(-50%) rotate(90deg);
      }
    }
  }
}
.tracking-system {
  margin-top: 320px;
  padding-bottom: 55px;
  .tracking-system-title {
    font-family: "Brand New Hyosung OTF";
    font-weight: 700;
    font-size: 48px;
    line-height: 57px;
    text-align: center;
    color: #ffffff;
  }
  .tracking-system-list {
    margin-top: 80px;
    display: flex;
    gap: 20px;
    .tracking-system-content {
      width: calc(33.3% - 12px);
      min-height: 180px;
      background: linear-gradient(180deg, #3b3b3c -33.26%, #1c1c1e 76.95%);
      border: 1px solid #444444;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 24px;
      line-height: 35px;
      text-align: center;
      color: #ffffff;
    }
  }
  @include media-breakpoint-down(lg) {
    margin-top: 160px;
    padding-bottom: 0;
    .tracking-system-title {
      font-size: 28px;
      line-height: 36px;
    }
    .tracking-system-list {
      margin-top: 40px;
      flex-direction: column;
      .tracking-system-content {
        width: 100%;
        min-height: 114px;
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
}
.cse-section-2 {
  padding: 160px 0;
  @include media-breakpoint-down(lg) {
    padding: 40px 0;
  }
  @at-root {
    .cse-section-title {
      font-weight: 700;
      font-size: 34px;
      line-height: 41px;
      text-align: center;
      color: #161618;
      padding: 180px 0;
    }
    @include media-breakpoint-down(lg) {
      .cse-section-title {
        font-size: 28px;
        line-height: 36px;
        padding: 60px 0 117px;
      }
    }
  }
  .gmg-wrap {
    border: 1px dashed #777;
    margin-bottom: 80px;
    @include media-breakpoint-down(lg) {
      padding: 0 20px;
    }
    .gmg-content-head {
      width: 95%;
      padding: 60px 0;
      text-align: center;
      background: #161618;
      border: 1px solid #444444;
      box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
      margin: -80px auto 0;
      .gmg-head-title {
        font-family: "Brand New Hyosung OTF";
        font-weight: 700;
        font-size: 32px;
        line-height: 38px;
        color: #ff233d;
      }
      .gmg-head-desc {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #dddddd;
        margin-top: 30px;
      }
      @include media-breakpoint-down(lg) {
        width: 100%;
        padding: 30px;
        .gmg-head-title {
          font-size: 24px;
          line-height: 34px;
        }
        .gmg-head-desc {
          font-size: 14px;
          line-height: 22px;
        }
      }
    }

    .gmg-content-body {
      margin-top: 160px;
    }
    @include media-breakpoint-down(lg) {
      .gmg-content-body {
        margin-top: 140px;
      }
    }
    .gmg-body-list {
      display: flex;
      gap: 20px;
      position: relative;
      z-index: 1;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 235px;
        background-image: url(../images/box-arrow.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        top: -115px;
        left: 50%;
        transform: translateX(-50%) rotate(180deg);
        z-index: -1;
      }
      @include media-breakpoint-down(lg) {
        flex-direction: column;
        gap: 30px;
        &::after {
          width: calc(100% + 76px);
        }
      }
      .gmg-body-content {
        width: calc(33.3% - 12px);
        .box {
          padding: 33px 0 27px;
          text-align: center;
          background: linear-gradient(180deg, #3b3b3c -33.26%, #1c1c1e 76.95%);
          .box-title {
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            color: #ffffff;
          }
          .box-desc {
            font-size: 16px;
            line-height: 24px;
            color: #ff233d;
            margin-top: 6px;
          }
        }
        ul {
          background: #fbfbfb;
          border: 1px solid #dddddd;
          padding: 30px;
          margin-top: 20px;
          li {
            font-size: 16px;
            line-height: 24px;
            color: #161618;
            position: relative;
            padding-left: 15px;
            padding-bottom: 12px;
            &:last-child {
              padding-bottom: 0;
            }
            &::before {
              content: "";
              position: absolute;
              top: 10px;
              left: 0;
              width: 3px;
              height: 3px;
              border-radius: 50%;
              background-color: #161618;
            }
          }
        }
        @include media-breakpoint-down(lg) {
          width: 100%;
          .box {
            padding: 30px 0;
            .box-title {
              font-size: 24px;
              line-height: 34px;
            }
            .box-desc {
              font-size: 14px;
              line-height: 22px;
              margin-top: 8px;
            }
          }
          ul {
            margin-top: 14px;
            li {
              font-size: 14px;
              line-height: 22px;
              padding-bottom: 16px;
            }
          }
        }
      }
    }
    .gmg-content-foot {
      padding: 40px 43px;
      margin-top: 60px;
      margin-bottom: -80px;
      background-color: #fff;
      border: 1px dashed #a8afba;
      &.top {
        margin-top: -80px;
        margin-bottom: 0;
        .gmg-foot-box {
          width: calc(20% - 10px);
        }
      }
      .gmg-foot-txt {
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        text-align: center;
        color: #161618;
        margin-bottom: 30px;
      }
      .gmg-foot-list {
        display: flex;
        gap: 20px;
      }
      .gmg-foot-box {
        width: calc(25% - 15px);
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
        padding: 30px 0;
        background: #777777;
        box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
      }
      @include media-breakpoint-down(lg) {
        padding: 30px 20px;
        margin-top: 140px;
        &.top {
          margin-top: -80px;
          margin-bottom: 0;
          .gmg-foot-box {
            width: 100%;
          }
        }
        .gmg-foot-txt {
          font-size: 18px;
          line-height: 22px;
        }
        .gmg-foot-list {
          flex-direction: column;
        }
        .gmg-foot-box {
          width: 100%;
          min-height: 104px;
          font-size: 18px;
          line-height: 22px;
          padding: 30px 22px;
        }
      }
    }
    .gmg-conten-line {
      background: #202022;
      box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
      padding: 41px 0;
      font-weight: 700;
      font-size: 22px;
      line-height: 26px;
      text-align: center;
      color: #ffffff;
      margin-top: 60px;
      @include media-breakpoint-down(lg) {
        margin-top: 140px;
        font-size: 24px;
        line-height: 34px;
        padding: 30px 0;
      }
    }
  }
}
.cse-section-3 {
  padding: 160px 0;
  .cse-section-title {
    padding: 0;
  }
  @include media-breakpoint-down(lg) {
    padding: 100px 0 80px;
  }
}
.cse-section-4 {
  padding: 160px 0;
  .cse-section-title {
    padding: 0;
  }
  @include media-breakpoint-down(lg) {
    padding: 80px 0 200px;
  }
}
// ----- ESG 개요 end
