.btn-top-area {
  padding-bottom: 15px;
  margin-top: -61px;
  position: sticky;
  right: 18px;
  bottom: 0;
  z-index: 98;
  float: right;
  transition: bottom 0.3s, transform 0.3s 0.05s;
  &::after {
    content: "";
    display: block;
    clear: both;
  }
  @include media-breakpoint-up(lg) {
    margin-top: -77px;
  }
  .btn-top {
    width: 46px;
    height: 46px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    font-size: 13px;
    font-weight: 700;
    margin-left: auto;
    @include media-breakpoint-up(lg) {
      width: 62px;
      height: 62px;
      font-size: 14px;
    }
    @include media-breakpoint-up(xl) {
      right: 40px;
      bottom: 20px;
    }
  }
  @include media-breakpoint-down(lg) {
    &.slide-up {
      bottom: 100px;
    }
  }
  #header:not(.fixed-header) ~ & {
    transform: translateY(100px);
  }
}

#footer {
  background: $dark-gray-1;
  padding: 60px 0 50px;
  .footer {
    .bottom {
      margin-top: 40px;
      justify-content: space-between;
    }
    .list {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 30px;
      li {
        position: relative;
        a {
          font-weight: 700;
          font-size: 15px;
          line-height: 18px;
          text-align: right;
          color: $white;
        }
      }
    }
    .desc {
      .address {
        display: flex;
        gap: 25px;
        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #999999;
        }
      }
      .copyright {
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #a4a4a4;
        margin-top: 12px;
      }
    }
    .news-content {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 32px;
      .news-letter {
        display: flex;
        gap: 7px;
        span {
          font-weight: 700;
          font-size: 14px;
          line-height: 22px;
          color: $light-gray-4;
        }
      }
      .family-select {
        width: 250px;
        background: #111111;
        border-radius: 27px;
        appearance: none;
        padding: 12px 30px;
        color: $white;
        font-weight: 700;
        font-size: 18px;
        line-height: 30px;
        color: #ffffff;
        background-image: url("../images/footer-arrow.svg");
        background-size: 14px;
        background-repeat: no-repeat;
        background-position: 90% center;
        border: 0;
        option {
          color: $white;
          font-weight: 700;
          font-size: 14px;
          line-height: 30px;
          color: #ffffff;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      .list {
        li {
          &::after {
            content: "";
            position: absolute;
            width: 1px;
            height: 10px;
            background-color: rgba(255, 255, 255, 0.2);
            right: -15px;
            top: 50%;
            transform: translateY(-50%);
          }

          &:last-child {
            &::after {
              content: none;
            }
          }
        }
      }
    }
    @include media-breakpoint-down(lg) {
      .bottom {
        margin-top: 24px;
      }
      @at-root {
        #footer {
          padding: 30px 0 20px;
        }
      }
      .footer-logo {
        display: block !important;
        text-align: center;
        margin: 0 auto;
      }
      .list {
        width: 70%;
        flex-flow: wrap;
        justify-content: center;
        gap: 0;
        margin: 30px auto 0;
        li {
          padding: 0 10px;
          a {
            font-size: 11px;
            line-height: 14px;
          }
        }
      }
      .desc {
        text-align: center;
        padding: 0 20px;
        .address {
          gap: 0;
          justify-content: center;
          span {
            font-size: 10px;
            line-height: 14px;
          }
        }
        .copyright {
          font-size: 10px;
          line-height: 12px;
          margin-top: 14px;
          color: #666;
        }
      }
      .news-content {
        display: block;
        .news-letter {
          justify-content: center;
          margin-top: 35px;
          span {
            font-size: 12px;
            line-height: 22px;
          }
        }
        .family-select {
          width: 100%;
          font-size: 14px;
          margin-top: 16px;
        }
      }
    }
  }
}

// 추가
#footer {
  .news-letter {
    & + .accordion-footer-sustainability-family-site {
      @include media-breakpoint-down(lg){
        margin-top: 24px;
      }
    }
  }
  .icon-familysite-direction {
    display: inline-block;
    position: relative;
    width: 14px;
    height: 8px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../images/icon-familysite-direction.svg');
  }

  .icon-familysite-direction .text-wrap .text {
    position: absolute;
    border: 0;
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    width: 0;
    height: 0;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    white-space: nowrap;
  }

  // footer 패밀리 사이트 아코디언
  .accordion-footer-sustainability-family-site {
    display:inline-block;
    position:relative;
    width:100%;
    max-width:250px;
    .button-family-site {
        position:relative;
        width:100%;
        height:54px;
        padding:10px 30px;
        background-color:rgba(17,17,17,1);
        border-radius:27px;
        .text-button-wrap {
            display:block;
            font-size:0;
            text-align:left;
            .text-wrap {
                display:inline-block;
                font-size:0;
                .text {
                    font-family:'Pretendard';
                    font-weight:700;
                    font-size:18px;
                    line-height:30px;
                    color:rgba(255,255,255,1);
                }
            }
        }
        .icon-familysite-direction {
            position:absolute;
            top:50%;
            right:29px;
            transform:translate3d(0,-50%,0) scale3d(1,1,1) rotate3d(0,0,0,0deg);
            transition-duration:.3s;
        }
        &.active {
            .icon-familysite-direction {
                transform:translate3d(0,-50%,0) scale3d(1,1,1) rotate3d(0,0,1,-540deg);
            }
            ~ .family-site-layer-wrap {
                max-height:300px;
                z-index:1; 
            }
        }
    }
    .family-site-layer-wrap {
        width:100%;
        max-width:calc(100% - 44px);
        max-height:0;
        text-align:left;
        position:absolute;
        left:22px;
        bottom:calc(100% - 2px);
        overflow-y:auto;
        transition-duration:.3s;
        &::-webkit-scrollbar{
            display:block;
            width:5px;
        }
        &::-webkit-scrollbar-thumb{
            height:10%;
            background-color: #FF233D;
        }
        &::-webkit-scrollbar-track{
            background-color:rgba(170,170,170,1);
        }
        .family-site-layer-list {
            padding-top:10px;
            padding-bottom:10px;
            background-color:rgba(51,51,51,1);
            border:1px solid rgba(22,22,24,1);
            .family-site-layer-item {
                display:block;
                .link-text-family-site-item {
                    display:block;
                    font-size:0;
                    padding-left:18px;
                    padding-right:18px;
                    transition-duration:.3s;
                    text-align:left;
                    .text-wrap {
                        display:inline-block;
                        font-size:0;
                        .text {
                            display:inline-block;
                            font-family:'Pretendard';
                            font-weight:500;
                            font-size:14px;
                            line-height:30px;
                            color:rgba(255,255,255,.5);
                            transition-duration:.3s;
                        }
                    }
                    &:focus,
                    &:hover {
                        transition-duration:.3s;
                        background-color:rgba(22,22,24,1);
                        .text-wrap {
                            .text {
                                color:rgba(255,255,255,1);
                                transition-duration:.3s;
                            }
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(lg){
      max-width:unset;
      .button-family-site {
          padding:15px 32px 16px;
          height:48px;

          .icon-familysite-direction {
            width:11px;
            height:7px;
            background-image:url('../images/icon-familysite-direction-mobile.svg');
            transition-duration:.3s;
          }

          .text-button-wrap {
              .text-wrap {
                  .text  {
                      font-size:14px;
                      line-height:17px;
                      text-transform:uppercase;
                  }
              }
          }
      }
    }
  }
}
