// 윤리제보센터
.ethics-wrap {
  padding: 160px 0 180px;
  h1 {
    margin-bottom: 160px;
    font-weight: 700;
    font-size: 48px;
    line-height: 57px;
    font-family: "Brand New Hyosung OTF";
  }
  h2 {
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 34px;
    line-height: 41px;
    color: $dark-gray-1;
    font-family: "Brand New Hyosung OTF";
  }
  .essential {
    position: relative;
    &::after {
      position: absolute;
      top: -4px;
      display: inline-block;
      margin-left: 4px;
      content: "*";
      vertical-align: super;
      color: $primary;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }
  }
  .intro-text {
    margin-bottom: 160px;
    p {
      margin-bottom: 20px;
      font-weight: 600;
      font-size: 22px;
      line-height: 32px;
      color: $dark-gray-4;
      strong {
        display: inline-block;
        font-weight: 700;
        font-size: 38px;
        line-height: 60px;
        color: $dark-gray-1;
        font-family: "Brand New Hyosung OTF";
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    .list {
      display: flex;
      margin: 40px 0 60px;
      padding: 50px 0 50px 60px;
      background-color: $light-gray-3;
      ul {
        li {
          position: relative;
          padding-left: 24px;
          font-weight: 400;
          font-size: 16px;
          line-height: 30px;
          color: $gray-3;
          &::before {
            display: inline-block;
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            width: 3px;
            height: 3px;
            border-radius: 100%;
            background-color: $gray-3;
            content: "";
          }
        }
      }
    }
  }
  .intro-rule {
    margin-bottom: 197px;
    .list {
      p {
        margin-bottom: 40px;
        font-weight: 600;
        font-size: 22px;
        line-height: 32px;
        color: $dark-gray-4;
      }
      li {
        position: relative;
        padding-left: 24px;
        margin-bottom: 20px;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        color: $dark-gray-4;
        &::before {
          display: inline-block;
          position: absolute;
          left: 10px;
          top: 16px;
          width: 3px;
          height: 3px;
          border-radius: 100%;
          background-color: $dark-gray-4;
          content: "";
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    padding: 100px 0 200px;
    h1 {
      margin-bottom: 110px;
      font-size: 34px;
      text-align: center;
      line-height: 41px;
    }
    h2 {
      font-size: 24px;
      line-height: 34px;
    }
    .intro-text {
      margin-bottom: 80px;
      p {
        font-size: 20px;
        line-height: 30px;
        strong {
          margin-bottom: 40px;
          font-size: 28px;
          line-height: 36px;
        }
      }
      .list {
        flex-direction: column;
        margin: 40px 0;
        padding: 40px 20px;
        ul {
          margin-right: 0;
          li {
            position: relative;
            padding-left: 24px;
            line-height: 26px;
            &::before {
              left: 10px;
            }
          }
        }
      }
    }
    .intro-rule {
      margin-bottom: 100px;
      .list {
        li {
          padding-left: 24px;
          font-size: 16px;
          line-height: 26px;
          &::before {
            left: 10px;
            top: 11px;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .bg-section {
    padding: 123px 0 180px;
    background-color: #fbfbfb;
  }
  .ethics-select {
    margin-bottom: 160px;
    .btns.case-1 {
      margin-bottom: 50px;
    }
    .btn.btn-lg.btn-outline-gray {
      display: inline-block;
      @include media-breakpoint-down(lg){
        display: block;
        width: 100%;
        &~ .btn.btn-lg.btn-outline-gray {
          margin-top: 10px;
        }
      }
    }
    p {
      font-size: 18px;
      line-height: 32px;
      color: #444;
      margin-bottom: 30px;
    }
    ul {
      li {
        width: calc((100% - 20px) / 2);
        padding: 50px 40px 200px;
        border: 1px solid $light-gray-2;
        .title {
          margin-bottom: 20px;
          font-weight: 600;
          font-size: 28px;
          line-height: 38px;
          color: $dark-gray-1;
          font-family: "Brand New Hyosung OTF";
        }
        p {
          margin-bottom: 60px;
          color: $dark-gray-4;
          font-size: 18px;
          line-height: 32px;
        }
        button {
          padding: 12px 20px;
          margin-bottom: 50px;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: $dark-gray-1;
          border: 1px solid $dark-gray-1;
        }
        .btns {
          button {
            &:first-child {
              margin-right: 17px;
            }
          }
        }
        &:first-child {
          margin-right: 20px;
          background: $white url("../images/ethics-intro-01-01.png") no-repeat 0
            100%;
        }
        &:last-child {
          background: $white url("../images/ethics-intro-02-02.png") no-repeat 0
            100%;
        }
      }
    }
  }
  .another {
    margin-right: 20px;
    color: $dark-gray-4;
    font-size: 16px;
    line-height: 26px;
    .box {
      margin: 30px 0 40px;
      padding: 40px 60px;
      border: 1px solid $light-gray-1;
      background-color: $white;
    }
  }

  .report-process-accordion {
    .accordion-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 16px 30px;
      margin-bottom: -1px;
      font-size: 20px;
      text-align: left;
      font-weight: 600;
      line-height: 24px;
      color: $dark-gray-4;
      background-color: $light-gray-3;
      border: 1px solid $light-gray-1;
      &::after {
        display: inline-block;
        width: 48px;
        height: 48px;
        content: "";
        border: 1px solid $light-gray-1;
        border-radius: 100%;
        background-image: url("../images/icon-plus.svg");
        background-repeat: no-repeat;
        background-size: 10px;
        background-position: center;
        transition: 0s;
      }
      &.active {
        background: $dark-gray-4;
        color: $white;
        &::after {
          background-image: url("../images/icon-minus.svg");
          background-color: $light-gray-3;
          border-color: $dark-gray-2;
        }
      }
    }
    .accordion-body {
      display: none;
      padding: 60px 40px 100px 60px;
      border: 1px solid $light-gray-2;
      background-color: $white;
      li {
        margin-bottom: 50px;
        color: $dark-gray-4;
        strong {
          display: block;
          margin-bottom: 20px;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          span {
            font-size: 13px;
            line-height: 24px;
            vertical-align: text-top;
            margin-right: 6px;
          }
        }
        p {
          padding-left: 20px;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }
      }
      button {
        margin-top: 30px;
        border: 1px solid $dark-gray-1;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .bg-section {
      padding: 100px 0 200px;
    }
    .ethics-select {
      margin-bottom: 100px;
      ul {
        li {
          width: 100%;
          padding: 40px 20px 200px;
          .title {
            font-size: 22px;
            line-height: 32px;
          }
          p {
            margin-bottom: 50px;
            font-size: 14px;
            line-height: 22px;
          }
          button {
            width: 100%;
            width: 100%;
            padding: 20px 0;
          }
          .btns {
            button {
              &:first-child {
                margin: 0 0 10px 0;
              }
            }
          }
          &:first-child {
            background: $white url("../images/ethics-intro-01-01-mo.png")
              no-repeat 0 100%;
            background-size: 100% 200px;
          }
          &:last-child {
            background: $white url("../images/ethics-intro-02-02-mo.png")
              no-repeat 0 100%;
            background-size: 100% 200px;
          }
        }
      }
    }
    .another {
      margin-right: 0;
      .box {
        margin: 30px 0 20px;
        padding: 40px 30px;
      }
    }

    .report-process-accordion {
      .accordion-item {
        .accordion-button {
          padding: 20px;
          font-size: 18px;
          line-height: 22px;
          &::after {
            width: 30px;
            height: 30px;
          }
        }
      }
      .accordion-body {
        padding: 30px 20px 50px;
        .btns.case-1 {
          @include media-breakpoint-down(lg){
            width: 100%;
          }
        }
        .btn.btn-lg.btn-outline-gray {
          display: inline-block;
          @include media-breakpoint-down(lg){
            display: block;
            width: 100%;
            &~ .btn.btn-lg.btn-outline-gray {
              margin-top: 10px;
            }
          }
        }
        li {
          margin-bottom: 30px;
          strong {
            font-size: 18px;
            line-height: 22px;
            span {
              font-size: 11px;
            }
          }
          p {
            padding-left: 30px;
            font-size: 14px;
            line-height: 22px;
          }
        }
        button {
          width: 100%;
          margin-top: 20px;
        }
      }
    }
  }

  .agree-box-wrap {
    height: 300px;
    padding: 8px 8px 8px 0;
    margin-bottom: 15px;
    border: 1px solid $light-gray-2;
  }
  .agree-box {
    overflow-y: scroll;
    height: 284px;
    padding: 40px;
    font-size: 16px;
    line-height: 24px;
    color: $dark-gray-4;
    &::-webkit-scrollbar {
      width: 6px;
      border-radius: 3px;
      background-color: $light-gray-3;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: $light-gray-1;
    }
    ul > li {
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .agree-text1 {
      margin-bottom: 40px;
      strong {
        display: block;
        margin-bottom: 10px;
        font-weight: 600;
      }
    }
    .agree-text2 {
      padding-left: 30px;
      counter-reset: num;
      dt {
        font-weight: 500;
        &::before {
          content: counter(num) ") ";
          counter-increment: num 1;
        }
      }
      dd {
        margin: 0 0 20px 18px;
        &:last-child {
          margin: 0 0 0 18px;
        }
      }
    }
  }
  .agree-inp-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    a {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      text-decoration: underline;
      color: $gray-2;
    }
    .form-check {
      display: flex;
      align-items: center;
      margin-right: 11px;
      .form-check-input {
        &[type="checkbox"] {
          width: 32px;
          height: 32px;
          margin-right: 9px;
          background-size: 100%;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          -webkit-print-color-adjust: exact;
          background-image: url("../images/icon-agree-chk-off.png");
        }
        &:checked[type="checkbox"] {
          background-image: url("../images/icon-agree-chk-on.png");
        }
      }
      label {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $dark-gray-4;
      }
    }
  }
  .form-container {
    padding-top: 100px;
    h2 {
      margin-bottom: 34px;
    }
    .text-info {
      margin-top: 8px;
      font-size: 12px;
      line-height: 20px;
    }
    .exam-txt {
      font-size: 12px;
      line-height: 20px;
      color: $gray-1;
      margin-top: 0.5rem;
    }
    .form-box {
      display: flex;
      flex-direction: column;
      row-gap: 44px;
      .form-text-label {
        margin-bottom: 12px;
        font-size: 16px;
        line-height: 24px;
      }
      .form-control {
        background-color: #fbfbfb;
        border: 1px solid $gray-1;
        &:disabled {
          background-color: $light-gray-3;
          border: 1px solid $light-gray-2;
        }
      }
      .textarea-wrap {
        height: 300px;
        padding: 8px;
        background-color: #fbfbfb;
        border: 1px solid $gray-1;
        &.textarea-bg {
          background-color: $light-gray-3;
          border: 0;
          border: 1px solid $light-gray-2;
          textarea {
            border: 0;
          }
        }
        textarea {
          overflow-y: scroll;
          height: 284px;
          border: 0;
          &::-webkit-scrollbar {
            width: 6px;
            border-radius: 3px;
            background-color: $light-gray-3;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 3px;
            background-color: $light-gray-1;
          }
        }
      }
      .lable-file {
        width: calc(100% - 116px);
        color: $gray-3;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
      .btn-file {
        width: 96px;
        padding: 0;
        border: 1px solid $light-gray-1;
      }
      .row-radio-group {
        display: flex;
        align-items: center;
        .form-radio {
          display: flex;
          align-items: center;
          margin-right: 32px;
          .form-radio-label {
            font-size: 16px;
            line-height: 24px;
            color: $gray-1;
          }
          .form-radio-input {
            &[type="radio"] {
              width: 20px;
              height: 20px;
              margin-right: 8px;
              background-size: 100%;
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              -webkit-print-color-adjust: exact;
              background-image: url("../images/icon-radio-off.png");
            }
            &:checked[type="radio"] {
              background-image: url("../images/icon-radio-on.png");
            }
            &:checked[type="radio"] + label {
              color: $dark-gray-3;
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
      &.login-box {
        width: 432px;
        row-gap: 0;
        margin: 0 auto;
        .row {
          flex-direction: column;
          row-gap: 40px;
        }
        .btn-submit {
          button {
            width: 100%;
          }
        }
      }
    }
  }
  .btn-submit {
    text-align: center;
    margin-top: 80px;
    @include media-breakpoint-down(lg){
      .btn.btn-lg.btn-outline-gray {
        width: 100%;
      }
    }
    button {
      font-weight: 600;
    }
  }
  .top-text {
    p {
      font-weight: 700;
      font-size: 22px;
      line-height: 26px;
      color: $dark-gray-4;
      &:first-child {
        margin-bottom: 10px;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .agree-box-wrap {
      height: 240px;
      padding: 3px;
      margin-bottom: 23px;
    }
    .agree-box {
      height: 234px;
      padding: 20px;
      font-size: 14px;
      line-height: 22px;
      &::-webkit-scrollbar {
        width: 4px;
        border-radius: 4px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
      }
      ul > li {
        margin-bottom: 40px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .agree-text2 {
        padding-left: 20px;
      }
    }
    .agree-inp-line {
      margin-bottom: 60px;
      /*a{font-weight: 600;font-size: 14px;line-height: 22px;text-decoration: underline;color:$gray-2;}*/
      .form-check {
        .form-check-input {
          &[type="checkbox"] {
            width: 24px;
            height: 24px;
            margin-right: 10px;
          }
        }
        label {
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
    .form-container {
      .text-info {
        margin-top: 10px;
        font-size: 12px;
        line-height: 22px;
      }
      .form-box {
        row-gap: 24px;
        .row {
          -moz-column-gap: 20px;
          column-gap: 20px;
        }
        .col-lg-gap {
          margin-bottom: 20px;
        }
        .form-text-label {
          margin-bottom: 10px;
          font-size: 14px;
          line-height: 22px;
        }
        textarea {
          height: 184px;
        }
        .lable-file {
          font-size: 14px;
          line-height: 22px;
        }
        .btn-file {
          line-height: 19px;
        }
        .row-radio-group {
          flex-wrap: wrap;
          .form-radio {
            margin: 6px 22px 8px 0;
            .form-radio-label {
              font-size: 14px;
              line-height: 22px;
            }
            .form-radio-input {
              &[type="radio"] {
                width: 18px;
                height: 18px;
                margin-right: 6px;
              }
            }
          }
        }
        &.login-box {
          width: 100%;
          .row {
            row-gap: 30px;
          }
        }
      }
    }
    .btn-submit {
      button {
        width: 100%;
        font-weight: 600;
      }
    }
    .top-text {
      p {
        font-size: 18px;
        line-height: 24px;
        &:first-child {
          margin-bottom: 20px;
        }
      }
    }
  }
}
