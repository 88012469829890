.overflow-y-scroll{height: 100%;overflow-y: auto;overflow-x: hidden;}

.m-b-30{margin-bottom:30px;}

.g-10px{
  --bs-gutter-x: 0.625rem;
  --bs-gutter-y: 0.625rem;
}
.g-20px{
  --bs-gutter-x: 1.25rem;
  --bs-gutter-y: 1.25rem;
}
.gap-40px{
  gap: 40px !important;
}

.bg-dark{ background-color: $dark-gray-1 !important;}
.bg-black{ background-color: #000 !important;}