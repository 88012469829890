.navigation{
    width: 100%;
    display: flex; 
    align-items: center;
    justify-content: space-between;
    .pagination{
       width: 80%;
       display: flex; 
       align-items: center;
       justify-content: center;
       gap: 1rem;
       .page-item{
            &.active{
                .page-link{
                    color: $primary;
                    font-weight: 600;
                }
            }
       }
       .page-link{
            font-size: 14px; 
            line-height: 22px;
            color: #6a6a6a;
       }
    }
}