// icons
[class^=icon-]{display: inline-block;vertical-align: middle;background-repeat: no-repeat;background-position: 50% 50%;flex-shrink: 0;}
[class^=icon-]:not([class $="arrow"]){background-size: contain;}

.icon-left-arrow{
    width: 12px; height: 12px; background-image: url('../images/icon-left-arrow.svg');
}
.icon-right-arrow{
    width: 12px; height: 12px; background-image: url('../images/icon-right-arrow.svg');
}
.icon-icon-plus{
    width: 10px; height: 10px; background-image: url('../images/icon-plus.svg');
}
.icon-top-arrow{
    width: 12px; height: 12px; background-image: url('../images/icon-top-arrow.svg');
}
.icon-plus{
    width: 10px; height: 10px; background-image: url('../images/icon-plus.svg');
}
.icon-minus{
    width: 10px; height: 2px; background-image: url('../images/icon-minus.svg');
}
.icon-close{
    width: 10px; height: 10px; background-image: url('../images/icon-close.svg');
}
.icon-close-white{
  width: 24px; height: 24px; background-image: url('../images/icon-close-white.svg');
}
.icon-w-left-arrow{
    width: 12px; height: 12px; background-image: url('../images/icon-w-left-arrow.svg');
}
.icon-w-right-arrow{
    width: 12px; height: 12px; background-image: url('../images/icon-w-right-arrow.svg');
}
.icon-w-plus{
    width: 10px; height: 10px; background-image: url('../images/icon-w-plus.svg');
}
.icon-w-top-arrow{
    width: 12px; height: 12px; background-image: url('../images/icon-w-top-arrow.svg');
}
.icon-w-close{
    width: 10px; height: 10px; background-image: url('../images/icon-w-close.svg');
}
.icon-download{
    width: 24px; height: 24px; background-image: url('../images/icon-download.svg');
}
.icon-download-hover{
    width: 24px; height: 24px; background-image: url('../images/icon-download-hover.svg');
}
.icon-g-download{
    width: 24px; height: 24px; background-image: url('../images/icon-g-download.svg');
}
.icon-search{
    width: 24px; height: 24px; background-image: url('../images/icon-search.svg');
}
.icon-caution{
    width: 60px; height: 60px; background-image: url('../images/icon-caution.svg');
}
.icon-play{
    width: 48px; height: 48px; background-image: url('../images/icon-play.svg');
}
.icon-modal-close{
    width: 16px; height: 16px; background-image: url('../images/icon-modal-close.svg');
}
.icon-instagram{
    width: 25px; height: 25px; background-image: url('../images/icon-instagram.svg');
}
.icon-primary-left{
    width: 11px; height: 17px; background-image: url('../images/icon-primary-left.svg');
}
.icon-g-plus{
    width: 10px; height: 10px; background-image: url('../images/icon-g-plus.svg');
}
.icon-b-left-arrow{
  width: 12px; height: 12px; background-image: url('../images/icon-b-left-arrow.svg'); background-size: contain;
}
.icon-b-right-arrow{
  width: 12px; height: 12px; background-image: url('../images/icon-b-right-arrow.svg');  background-size: contain;
}
.icon-search-header{
  width: 20px; height: 20px; background-image: url('../images/icon-search-header.svg');
}
.icon-search-in-layer{
  width: 24px; height: 24px; background-image: url('../images/icon-search-in-layer.svg');
}
.icon-delete{
  width: 24px; height: 24px; background-image: url('../images/icon-delete.svg');
}
.icon-news-letter{
    width: 24px; height: 24px; background-image: url('../images/icon-news-letter.svg');
}

.icon-collection{
    width: 64px; height: 64px; background-image: url('../images/icon-collection.svg');
}
.icon-collection-active{
    width: 64px; height: 64px; background-image: url('../images/icon-collection-active.svg');
}
.icon-use{
    width: 64px; height: 64px; background-image: url('../images/icon-use.svg');
}
.icon-use-active{
    width: 64px; height: 64px; background-image: url('../images/icon-use-active.svg');
}
.icon-offer{
    width: 64px; height: 64px; background-image: url('../images/icon-offer.svg');
}
.icon-offer-active{
    width: 64px; height: 64px; background-image: url('../images/icon-offer-active.svg');
}
.icon-destruction{
    width: 64px; height: 64px; background-image: url('../images/icon-destruction.svg');
}
.icon-destruction-active{
    width: 64px; height: 64px; background-image: url('../images/icon-destruction-active.svg');
}
.icon-privacy-detail-01{
    width: 120px; height: 120px; background-image: url('../images/icon-privacy-detail-01.svg');
}
.icon-privacy-detail-02{
    width: 120px; height: 120px; background-image: url('../images/icon-privacy-detail-02.svg');
}
.icon-privacy-detail-03{
    width: 120px; height: 120px; background-image: url('../images/icon-privacy-detail-03.svg');
}
.icon-privacy-detail-04{
    width: 120px; height: 120px; background-image: url('../images/icon-privacy-detail-04.svg');
}
.icon-privacy-detail-05{
    width: 120px; height: 120px; background-image: url('../images/icon-privacy-detail-05.svg');
}
.icon-privacy-detail-06{
    width: 120px; height: 120px; background-image: url('../images/icon-privacy-detail-06.svg');
}
.icon-resources{
    width: 166px; height: 166px; background-image: url('../images/icon-resources.svg');
}
.icon-emission{
    width: 158px; height: 158px; background-image: url('../images/icon-emission.svg');
}
.icon-impact{
    width: 190px; height: 158px; background-image: url('../images/icon-impact.svg');
}
.icon-dot-arrow{
    width: 26px; height: 59px; background-image: url('../images/icon-dot-arrow.svg'); background-size: contain;
}
.icon-esg-small-arrow{
    width: 60px; height: 14px; background-image: url('../images/icon-esg-small-arrow.svg'); background-size: contain;
}
.icon-esg-b-arrow{
    width: 10px; height: 43px; background-image: url('../images/icon-esg-b-arrow.svg'); background-size: contain;
}
.icon-purpose-arrow{
    width: 14px; height: 60px; background-image: url('../images/icon-purpose-arrow.svg'); background-size: contain;
}
.icon-cse-01{
    width: 84px; height: 84px; background-image: url('../images/icon-cse-01.svg');
}
.icon-cse-02{
    width: 89px; height: 78px; background-image: url('../images/icon-cse-02.svg');
}
.icon-cse-03{
    width: 78px; height: 78px; background-image: url('../images/icon-cse-03.svg');
}
.icon-down {
    width: 12px; height: 12px; background-image: url('../images/icon-privacy-arrow.svg');
}

/* SNS 추가 */
.icon-youtube {
    width: 25px; height: 25px; background-image: url('../images/icon-youtube.svg');
}
.icon-facebook {
    width: 25px; height: 25px; background-image: url('../images/icon-facebook.svg');
}