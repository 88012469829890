//  미션/비전
.brand-section {
  &.strategy {
    background-color: $dark-gray-1;
    @include media-breakpoint-down(lg) {
      padding: 50px 0 180px;
    }
  }
  &.brand-system {
    background-color: $dark-gray-1;
    padding: 130px 0 36px;
    .brand-strategy-head {
      .brand-strategy-title {
        margin-bottom: 0;
      }
    }
    @include media-breakpoint-down(lg) {
      padding: 50px 0 24px;
    }
  }
  &.brand-esg {
    background-color: $dark-gray-1;
    padding: 130px 0 180px;
    .brand-strategy-head {
      .brand-strategy-title {
        margin-bottom: 0;
      }
    }
    @include media-breakpoint-down(lg) {
      padding: 80px 0 230px;
    }
  }
}
.brand-strategy-head {
  padding: 200px 0 100px;
  .brand-strategy-title {
    font-family: "Brand New Hyosung OTF";
    font-weight: 700;
    font-size: 34px;
    line-height: 48px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 60px;
  }
  @include media-breakpoint-down(lg) {
    padding: 80px 0 54px;
    .brand-strategy-title {
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 40px;
      text-align: left;
    }
  }
  .brand-strategy-desc {
    p {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #aaaaaa;
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
      @include media-breakpoint-down(lg) {
        text-align: left;
      }
    }
  }
}

.brand-strategy-subtitle {
  text-align: center;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-top: 40px;
}
@include media-breakpoint-down(lg) {
  .brand-strategy-subtitle {
    text-align: left;
    font-size: 20px;
    line-height: 32px;
    margin-top: 30px;
  }
}
.brand-mission-subtitle {
  text-align: center;
  color: #fff;
  font-weight: 700;
  font-size: 34px;
  line-height: 41px;
  margin-top: 80px;
  font-family: "Brand New Hyosung OTF";
}
@include media-breakpoint-down(lg) {
  .brand-mission-subtitle {
    text-align: left;
    font-size: 20px;
    line-height: 32px;
    margin-top: 30px;
  }
}
.brand-strategy-content {
  border: 1px dashed #444444;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
  padding: 60px 40px;
  position: relative;
  margin-top: 30px;

  @include media-breakpoint-down(lg) {
    padding: 20px 20px 0 20px;
    margin-top: 20px;
    .text-box {
      width: 90%;
      bottom: -61px;
      font-size: 18px;
      line-height: 24px;
      padding: 25px 0;
      border-radius: 108px;
    }
  }
}
.mission-text-box {
  width: 65%;
  margin: 140px auto 0;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: #fff;
  padding: 70px 48px;
  background-color: #161618;
  border: 1px solid #444444;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
  @include media-breakpoint-down(lg) {
    width: 100%;
    font-size: 18px;
    line-height: 24px;
    padding: 25px 0;
    margin: 80px auto 0;
  }
}
.brand-arrow {
  text-align: center;
  margin: 28px 0;
  &.rotate {
    transform: rotate(180deg);
    margin-top: 30px;
  }
}

.mainpoint-dna-wrap {
  display: flex;
  gap: 20px;
  @include media-breakpoint-down(lg) {
    display: block;
  }
}
.dna-title {
  flex: 116px 0 0;
  background: linear-gradient(180deg, #3b3b3c -33.26%, #1c1c1e 76.95%);
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  @include media-breakpoint-down(lg) {
    font-size: 14px;
    line-height: 22px;
    padding: 24px 0;
    margin-bottom: 20px;
  }
}
.dna-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  @include media-breakpoint-down(lg) {
    flex-direction: row;
    gap: 14px;
    margin-bottom: 14px;
    &.type-2 {
      flex-direction: column-reverse;
      .dna-list-leader {
        font-size: 20px;
        line-height: 26px;
        padding: 24px 0;
        gap: 2;
      }
      .dan-category-txt {
        font-size: 16px;
        line-height: 26px;
        padding: 20px 0 30px;
        gap: 1;
      }
    }
  }
  .dna-list {
    display: flex;
    gap: 20px;
    div {
      width: 206px;
      border: 1px dashed #444444;
      font-size: 18px;
      line-height: 32px;
      text-align: center;
      color: $light-gray-1;
      padding: 26px 0;
      background-color: #202022;
    }
    @include media-breakpoint-down(lg) {
      order: 2;
      width: 70%;
      flex-direction: column;
      gap: 14px;
      div {
        width: 100%;
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
  .dan-category {
    border: 1px solid #444444;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #f5f5f5;
    padding: 13px 0;
    text-align: center;
    @include media-breakpoint-down(lg) {
      order: 1;
      width: 30%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .dna-list-leader {
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    color: #ff233d;
    background: #000000;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
    padding: 32px 0;
  }
  .dan-category-txt {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #aaaaaa;
    padding: 24px 0;
  }
}
.strategy-section-2 {
  padding: 180px 0;
  .title-box {
    .title {
      font-weight: 700;
      font-size: 34px;
      line-height: 48px;
      text-align: center;
      color: $dark-gray-1;
    }
    .sub-title {
      font-weight: 700;
      font-size: 24px;
      line-height: 35px;
      text-align: center;
      color: $dark-gray-1;
      span {
        font-weight: 300;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    padding: 100px 0 200px;
    .title-box {
      .title {
        font-size: 24px;
        line-height: 34px;
        text-align: left;
        font-family: 'Brand New Hyosung OTF';
      }
      .sub-title {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        span {
          font-weight: 400;
        }
        strong {
          font-weight: 400;
        }
        &.case-1 {
          font-size: 20px;
          line-height: 26px;
          strong {
            font-weight: 700;
          }
        }
      }
    }
  }
  @at-root {
    .mission-content {
      margin-top: 100px;
      display: flex;
      flex-direction: column;
      gap: 26px;
      @include media-breakpoint-down(lg) {
        width: 1330px;
        height: 100%;
        white-space: nowrap;
        overflow-x: auto;
        gap: 16px;
        flex-wrap: nowrap;
        justify-content: flex-start;
        padding: 0 18px;
        margin-top: 60px;
        &.case-1 {
          width: 100%;
          .mission-title {
            white-space: normal;
            font-size: 14px;
            line-height: 22px;
            padding: 30px 3%;
          }
        }
      }
      .mission-list {
        display: flex;
        gap: 20px;
      }
      .mission-title {
        width: 25%;
        background: linear-gradient(180deg, #3b3b3c -33.26%, #1c1c1e 76.95%);
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
        padding: 45px 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .mission-desc {
        width: 75%;
        background: #fbfbfb;
        border: 1px solid $light-gray-1;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: $dark-gray-1;
        padding: 45px 0;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: -20px;
          width: 20px;
          height: 1px;
          background-color: #ddd;
        }
      }
      .mission-row {
        width: 75%;
        gap: 20px;
        > div {
          padding: 0;
          width: calc(25% - 15px);
        }
        .mission-desc {
          width: 100%;
        }
      }
      .mission-exam {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #999999;
      }
      @include media-breakpoint-down(lg) {
        .mission-row {
          gap: 16px;
          > div {
            width: calc(25% - 12px);
          }
        }
        .mission-list {
          gap: 16px;
        }
        .mission-title {
          font-size: 20px;
          line-height: 24px;
          padding: 30px 0;
        }
        .mission-desc {
          font-size: 16px;
          line-height: 26px;
          padding: 30px 0;
          &::after {
            left: -16px;
            width: 16px;
          }
        }
      }
    }
  }
  .mission-value-wrap {
    display: flex;
    gap: 20px;
    margin-top: 33px;
    .mission-value-list {
      width: calc(33.3% - 12px);
      background: #f1f2f3;
      border: 1px solid $light-gray-1;
      box-shadow: 0px 4px 11px rgba(33, 35, 51, 0.18);
      padding: 70px 45px;
      position: relative;
      text-align: center;

      min-height: 350px;
      &::after {
        content: "";
        width: 54px;
        height: 9px;
        background-color: #ff4d57;
        position: absolute;
        top: -5px;
        left: 50%;
        transform: translateX(-50%);
      }

      .mission-value-title {
        font-weight: 700;
        font-size: 32px;
        line-height: 38px;
        color: $dark-gray-1;
        font-family: "Brand New Hyosung OTF";
      }
      .mission-value-subtitle {
        font-size: 16px;
        line-height: 19px;
        color: #202022;
        margin-top: 10px;
      }
      .mission-value-desc {
        font-size: 16px;
        line-height: 24px;
        color: $dark-gray-3;
        margin-top: 38px;
        width: 70%;
        margin-left: auto;
        margin-right: auto;
      }
    }
    @include media-breakpoint-down(lg) {
      flex-direction: column;
      .mission-value-list {
        width: 100%;
        padding: 60px 30px;
        min-height: unset;
        text-align: left;
        &::after {
          width: 54px;
          height: 7px;
          top: -4px;
        }
        .mission-value-title {
          font-size: 24px;
          line-height: 34px;
        }
        .mission-value-subtitle {
          font-size: 14px;
          line-height: 22px;
        }
        .mission-value-desc {
          font-size: 14px;
          line-height: 22px;
          margin-top: 30px;
          text-align: left;
          width: 100%;
        }
      }
    }
  }
}
//  미션/비전 -- end

//  아이덴티티
.identity-wrap {
  margin-top: 46px;
  @at-root {
    @include media-breakpoint-down(lg) {
      .scroll-wrap {
        overflow-x: scroll;
        overflow-y: hidden;
        margin-left: -18px;
        margin-right: -18px;
      }
    }
  }
}
.identity-content {
  display: flex;
  gap: 20px;
  @include media-breakpoint-down(lg) {
    width: 1100px;
    height: 100%;
    white-space: nowrap;
    overflow-x: auto;
    gap: 16px;
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding: 0 18px;
  }
  .identity-list {
    width: calc(25% - 6px);
    .identity-list-product {
      background: #ff233d;
      border: 1px solid rgba(0, 0, 0, 0.6);
      box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
      font-weight: 700;
      font-size: 18px;
      line-height: 32px;
      text-align: center;
      color: $light-gray-1;
      padding: 11px 0;
      margin-bottom: 20px;
    }
    .identity-list-head {
      height: 166px;
      background: #202022;
      border: 1px dashed #444444;
      box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
      font-size: 18px;
      line-height: 26px;
      text-align: center;
      color: #ffffff;
      padding: 44px 10%;
      margin-bottom: 80px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      white-space: normal;
      &.case-1 {
        height: auto;
        min-height: 114px;
      }
      &::before {
        content: "";
        position: absolute;
        bottom: -6px;
        left: 50%;
        transform: translateX(-50%);
        width: 53px;
        height: 6px;
        background-color: #ee424d;
      }
      &::after {
        content: "";
        position: absolute;
        bottom: -76px;
        left: 50%;
        transform: translateX(-50%);
        width: 1px;
        height: 70px;
        background-color: #ee424d;
      }
      @include media-breakpoint-down(lg) {
        font-size: 16px;
        padding: 26px 10%;
        margin-bottom: 38px;
        &::before {
          bottom: -4px;
          width: 42px;
          height: 4px;
        }
        &::after {
          bottom: -44px;
          height: 38px;
        }
      }
    }
    .identity-list-body {
      min-height: 262px;
      border: 1px solid #444444;
      box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
      padding: 64px 25px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &.case-1 {
        min-height: 230px;
        @include media-breakpoint-down(lg){
          min-height: 166px;
        }
      }
      &::before {
        content: "";
        position: absolute;
        top: -6px;
        left: 50%;
        transform: translateX(-50%);
        width: 11px;
        height: 11px;
        background-color: #ee424d;
        border-radius: 50%;
      }
      .title {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;

        white-space: normal;
      }
      .desc {
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        color: $light-gray-1;
        margin-top: 18px;
        width: 80%;
        white-space: normal;
      }
      @include media-breakpoint-down(lg) {
        padding: 50px 6%;
        .title {
          font-size: 18px;
          line-height: 22px;
        }
        .desc {
          font-size: 14px;
          line-height: 22px;
          margin-top: 31px;
          width: 100%;
        }
      }
    }
  }
}
.identity-content-foot {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  margin-top: 60px;
  .identity-btn {
    max-width: 472px;
    padding: 29px 65px;
    border: 1px solid $dark-gray-3;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
    font-weight: 300;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #ffffff;
    position: relative;
    margin-top: 20px;
    strong {
      font-weight: 700;
    }
    .hidden-line {
      width: 22px;
      height: 22px;
      position: absolute;
      &.top-left {
        border-top: 2px solid #575757;
        border-left: 2px solid #575757;
        top: 0;
        left: 0;
      }
      &.top-right {
        border-top: 2px solid #575757;
        border-right: 2px solid #575757;
        top: 0;
        right: 0;
      }
      &.bottom-left {
        border-bottom: 2px solid #575757;
        border-left: 2px solid #575757;
        bottom: 0;
        left: 0;
      }
      &.bottom-right {
        border-bottom: 2px solid #575757;
        border-right: 2px solid #575757;
        bottom: 0;
        right: 0;
      }
    }
  }
  &.esg-foot {
    margin-top: 0;
    padding-top: 180px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
      background-image: url("../images/brand-esg-arrow.svg");
      width: 100%;
      height: 177px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
    .identity-btn {
      max-width: 100%;
      padding: 52px 65px;
      margin-top: 0;
      p {
        font-weight: 300;
        font-size: 18px;
        line-height: 32px;
        text-align: center;
        color: $light-gray-1;
      }
      strong {
        font-size: 20px;
        color: #fff;
        line-height: 32px;
      }
    }
    @include media-breakpoint-down(lg) {
      .identity-btn {
        padding: 25px 28px;
        p {
          font-size: 20px;
        }
        strong {
          span {
            color: #fff !important;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    gap: 20px;
    margin-top: 48px;
    .identity-btn {
      max-width: 100%;
      width: 100%;
      padding: 26px 22px;
      font-size: 20px;
      line-height: 32px;
      margin-top: 12px;
    }
  }
}
.as-is {
  width: 65%;
  position: relative;
  margin: 85px auto 140px;

  .as-is-content {
    min-height: 216px;
    background: #f1f2f3;
    border: 1px solid $light-gray-1;
    box-shadow: 0px 4px 11px rgba(33, 35, 51, 0.18);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .asis-content-title {
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      color: $dark-gray-1;
      font-family: "Brand New Hyosung OTF";
    }
    .asis-content-desc {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #202022;
      margin-top: 30px;
    }
  }
  @include media-breakpoint-down(lg) {
    width: 100%;
    margin: 85px auto 100px;
    .as-is-content {
      .asis-content-title {
        font-size: 24px;
        line-height: 34px;
      }
      .asis-content-desc {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
  &::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
    top: -55px;
    background-image: url("../images/icon-w-arrow.svg");
    width: 40px;
    height: 147px;
    z-index: -1;
  }
}
.to-be {
  width: 65%;
  position: relative;
  margin: 100px auto 0;
  .to-be-content {
    min-height: 216px;
    background: $dark-gray-1;
    border: 1px solid #444444;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .tobe-content-title {
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      color: #fff;
      font-family: "Brand New Hyosung OTF";
    }
    .tobe-content-desc {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #fff;
      margin-top: 30px;
    }
  }
  @include media-breakpoint-down(lg) {
    width: 100%;
    margin: 40px auto 0;
    .to-be-content {
      .tobe-content-title {
        font-size: 24px;
        line-height: 34px;
      }
      .tobe-content-desc {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}
.strategy-section-bg {
  width: 100%;
  min-height: 878px;
  background-image: url("../images/identity-section-bg.jpg");
  background-size: cover;
  background-position: center;
  text-align: center;
  padding: 180px 0 240px;
  .strategy-section-txt {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }
  .strategy-section-txt2 {
    font-size: 18px;
    line-height: 32px;
    color: #ffffff;
    margin-top: 118px;
  }
  .strategy-section-txt3 {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: #ffffff;
    margin: 18px 0 66px;
    font-family: "Brand New Hyosung OTF";
    margin-top: 18px;
  }
  .strategy-section-title {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: 0.56em;
    text-transform: uppercase;
    color: #ff233d;
  }
  @include media-breakpoint-down(lg) {
    min-height: 717px;
    padding: 60px 0 200px;
    .strategy-section-txt {
      font-size: 14px;
      line-height: 22px;
    }
    .strategy-section-txt2 {
      font-size: 14px;
      line-height: 22px;
      margin-top: 60px;
    }
    .strategy-section-txt3 {
      font-size: 24px;
      line-height: 34px;
      margin: 30px 0 50px;
    }
    .strategy-section-title {
      font-size: 22px;
      line-height: 26px;
      letter-spacing: 0.49em;
      margin-top: 30px;
    }
  }
}
//  아이덴티티 -- end

// 브랜드 체계
.system-wrap {
  margin-top: 0;
  // @include media-breakpoint-down(lg){
  //   margin-top: 0;
  // }
}
.strategy-section-4 {
  position: relative;
  z-index: 1;
  padding-bottom: 200px;
  overflow-x: hidden;
  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    z-index: -1;
    border-top: 15vw solid $dark-gray-1;
    border-left: 50vw solid transparent;
    border-right: 50vw solid transparent;
  }
  @include media-breakpoint-down(lg) {
    &::after {
      border-top: 500px solid $dark-gray-1;
      border-right: 50vw solid transparent;
    }
  }
}
.product-system-wrap {
  background: #fbfbfb;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
  padding: 30px 42px 40px;
  text-align: center;
  @include media-breakpoint-down(lg) {
    padding: 26px 20px 60px;
  }
  .product-system-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }
  .hyosung-badge {
    background: #270089;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 22px 50px;
    display: inline-block;
    margin: 20px 0 40px;
    font-family: "Brand New Hyosung OTF";
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    color: #ffffff;
    @include media-breakpoint-down(lg) {
      margin: 20px 0 50px;
      font-size: 15px;
      line-height: 22px;
      padding: 19px 22px;
    }
  }

  .system-list {
    display: flex;
    flex-flow: wrap;
    gap: 20px;
    .system-content {
      width: calc(16.6% - 16px);
      .system-content-head {
        background-color: $dark-gray-1;
        font-weight: 700;
        font-size: 18px;
        line-height: 32px;
        text-align: center;
        color: $light-gray-1;
        padding: 26px 0;
      }
      .system-content-body {
        height: 170px;
        background: $dark-gray-3;
        box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px 0;
        margin-top: 12px;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          width: 1px;
          height: 12px;
          top: -12px;
          background-color: #ddd;
        }
        li {
          font-size: 18px;
          line-height: 26px;
          text-align: center;
          color: #ffffff;
          padding-bottom: 16px;
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
    @include media-breakpoint-down(lg) {
      gap: 13px;
      .system-content {
        width: calc(33.3% - 9px);
        .system-content-head {
          font-size: 12px;
          line-height: 22px;
          padding: 16px 0;
        }
        .system-content-body {
          height: 115px;
          padding: 20px 0;
          li {
            font-size: 12px;
            line-height: 22px;
            padding-bottom: 4px;
            &:last-child {
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }
}
//  브랜드 체계 -- end

// 브랜드 ESG
.esg-content-1 {
  padding-bottom: 120px;
  @at-root {
    .esg-title {
      font-size: 34px;
      line-height: 48px;
      text-align: center;
      color: $dark-gray-1;
      font-family: "Brand New Hyosung OTF";
      font-weight: bold;
      color: #161618;
    }
    .esg-desc {
      margin-top: 180px;
      text-align: center;
      font-size: 27px;
      line-height: 120%;
      color: #161618;
      strong {
        font-weight: 700;
      }
    }
    @include media-breakpoint-down(lg) {
      .esg-title {
        text-align: left;
        font-size: 24px;
        line-height: 34px;
      }
      .esg-desc {
        margin-top: 60px;
        font-size: 24px;
        line-height: 34px;
      }
    }
  }
  .esg-group-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 70px;

    .esg-group-title {
      background: #f1f2f3;
      border: 1px solid $light-gray-1;
      box-shadow: 0px 4px 11px rgba(33, 35, 51, 0.18);
      font-weight: 700;
      font-size: 22px;
      line-height: 26px;
      text-align: center;
      color: $dark-gray-1;
      padding: 29px 120px;
      display: inline-block;
      position: relative;
      font-family: "Brand New Hyosung OTF";
      &::before {
        content: "";
        position: absolute;
        bottom: -32px;
        left: 50%;
        transform: translateX(-50%);
        width: 1px;
        height: 32px;
        background-color: #aaa;
      }
    }
    .esg-group-list {
      display: flex;
      gap: 20px;
      position: relative;
      margin-top: 50px;
      &::after {
        content: "";
        position: absolute;
        width: calc(75% + 17px);
        height: 1px;
        background-color: #aaa;
        left: 50%;
        transform: translateX(-50%);
        top: -20px;
      }
    }
    .esg-group-content {
      width: calc(25% - 15px);
      text-align: center;
    }
    @include media-breakpoint-down(lg) {
      margin-top: 60px;
      .esg-group-title {
        background: transparent;
        border: 0;
        box-shadow: none;
        font-size: 20px;
        line-height: 32px;
        padding: 0;
        position: relative;
        &::before {
          content: none;
        }
      }
      .esg-group-list {
        gap: 23px;
        flex-direction: column;
        margin-top: 23px;
        &::after {
          content: none;
        }
      }
      .esg-group-content {
        width: 100%;
        text-align: left;
        position: relative;
      }
    }
    .box {
      background: #f1f2f3;
      border: 1px solid #dddddd;
      box-shadow: 0px 4px 11px rgba(33, 35, 51, 0.18);
      padding: 64px 10% 20px;
      min-height: 265px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
        width: 1px;
        height: 20px;
        background-color: #aaa;
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 53px;
        height: 6px;
        background-color: #ee424d;
      }
      .title {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: $dark-gray-1;
      }
      .desc {
        font-weight: 300;
        font-size: 16px;
        line-height: 30px;
        color: $dark-gray-3;
        margin-top: 18px;
        strong {
          font-weight: 700;
        }
      }
      @include media-breakpoint-down(lg) {
        padding: 60px 30px 80px;
        min-height: unset;
        height: auto;
        &::before {
          content: none;
        }
        &::after {
          top: -4px;
          width: 54px;
          height: 7px;
        }
        .title {
          font-size: 20px;
          line-height: 32px;
        }
        .desc {
          font-size: 14px;
          line-height: 22px;
          margin-top: 24px;
        }
      }
    }
    .exam-txt {
      font-weight: 500;
      font-size: 16px;
      line-height: 30px;
      text-align: center;
      color: #777777;
      margin-top: 18px;
    }
    @include media-breakpoint-down(lg) {
      .exam-txt {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: left;
        margin-top: 18px;
        position: absolute;
        bottom: 16px;
        left: 30px;
      }
    }
  }
}
.esg-content-2 {
  padding: 120px 0 180px;
  background-color: #f8f8f8;
  .nature-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 90px;
    position: relative;
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      top: 220px;
      left: 50%;
      transform: translateX(-50%);
      background-image: url("../images/brand-esg-arrow2.svg");
      width: 100%;
      height: 177px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      z-index: -1;
    }
  }
  .nature-list {
    display: flex;
    gap: 30px;
  }
  @include media-breakpoint-down(lg) {
    padding: 0;
    background-color: #fff;
    .nature-wrap {
      flex-direction: column;
      margin-top: 60px;
      &::before {
        top: -175px;
        background-image: url("../images/mo-brand-esg-arrow2.svg");
        width: calc(100% + 36px);
        height: 1200px;
      }
    }
    .nature-list {
      flex-direction: column;
      gap: 36px;
    }
  }
  .nature-content {
    width: 206px;
    height: 206px;
    .box {
      width: 100%;
      height: 100%;
      background: #f1f2f3;
      border: 1px solid #dddddd;
      box-shadow: 0px 4px 11px rgba(33, 35, 51, 0.18);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
    .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 32px;
      color: #161618;
    }
    .desc {
      font-size: 16px;
      line-height: 24px;
      color: #333333;
      margin-top: 24px;
      text-align: center;
    }
    &.result {
      width: 228px;
      height: 228px;
      border: 1px dashed #444444;
      filter: drop-shadow(0px 7px 10px rgba(0, 0, 0, 0.25));
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      .box {
        width: 206px;
        height: 206px;
        background-color: #202022;
        box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
      }
      .title {
        color: #fff;
      }
    }
    @include media-breakpoint-down(lg) {
      width: 206px;
      height: auto;
      .box {
        width: 100%;
        height: 206px;
      }
      .title {
        font-size: 18px;
        line-height: 22px;
      }
      .desc {
        margin-top: 16px;
      }
    }
  }
  .icon-esg-small-arrow {
    margin: 0 36px 0 13px;
  }
  @include media-breakpoint-down(lg) {
    .icon-esg-small-arrow {
      margin: 30px 0;
      transform: rotate(90deg);
    }
  }
}
//  브랜드 ESG -- end

//  브랜드 group
.brand-group-list {
  .brand-group-content {
    width: 100%;
    height: 84px;
    display: flex;
    align-items: center;
    gap: 40px;
    margin-bottom: 60px;
    .title {
      width: 270px;
      height: 100%;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
      background-color: $red;
      padding: 30px 0;
      border-radius: 4px;
      text-align: center;
      flex-shrink: 0;
    }
    .desc {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 18px;
      line-height: 30px;
      color: #fff;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #444;
        bottom: -30px;
        left: 0;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .brand-group-content {
      height: auto;
      flex-direction: column;
      gap: 20px;
      margin-bottom: 40px;
      .title {
        width: 100%;
        font-size: 16px;
        line-height: 19px;
        padding: 8px 0;
        border-radius: 0;
      }
      .desc {
        font-size: 14px;
        line-height: 22px;
        &::after {
          content: none;
        }
      }
    }
  }
}
.group {
  .brand-strategy-head {
    .brand-strategy-title {
      margin-bottom: 0;
    }
  }
  @include media-breakpoint-down(lg) {
    padding: 50px 0 0;
  }
}
.section-group-1 {
  padding: 120px 0 160px;
  position: relative;
  &::before {
    content: "";
    background-image: url("../images/brand-group-arrow.svg");
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 60% 30%;
    z-index: -1;
  }
  @include media-breakpoint-down(lg) {
    padding: 100px 0;
    &::before {
      background-position: 50% 8%;
    }
  }
}
.step-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #161618;
}
@include media-breakpoint-down(lg) {
  .step-title {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }
}
.brand-group-box {
  border-bottom: 1px solid #444;
  padding: 50px 0;
  @include media-breakpoint-down(lg) {
    padding: 40px 22px 40px 30px;
    &.card-layout {
      padding: 40px 0;
    }
    .box-wrap {
      margin-top: 24px;
    }
  }
  .type-1 {
    text-align: center;
    .title {
      font-weight: 700;
      font-size: 22px;
      line-height: 26px;
      color: $red;
    }
    .desc {
      font-size: 18px;
      line-height: 30px;
      color: #444444;
      margin-top: 10px;
    }
    @include media-breakpoint-down(lg) {
      .title {
        font-size: 18px;
        line-height: 22px;
        margin-top: 24px;
      }
      .desc {
        font-size: 14px;
        line-height: 22px;
        margin-top: 10px;
      }
    }
  }
  .type-2 {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    gap: 2px;
    > div {
      width: 35%;
      background-color: #fff;
      padding: 40px;
      border: 1px solid rgba(250, 218, 218, 0.4);
    }
    .title {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: $red;
    }
    .desc {
      font-size: 16px;
      line-height: 28px;
      color: rgba(224, 44, 44, 0.5);
      margin-top: 8px;
      font-weight: 600;
    }
    ul {
      margin-top: 20px;
      li {
        font-size: 14px;
        line-height: 20px;
        color: #161618;
        position: relative;
        padding-left: 15px;
        &::before {
          content: "";
          width: 3px;
          height: 3px;
          border-radius: 50%;
          background-color: #444;
          position: absolute;
          top: 9px;
          left: 0;
        }
      }
    }
    @include media-breakpoint-down(lg) {
      padding: 0;
      gap: 8px;
      > div {
        width: 100%;
        padding: 30px 20px;
      }
      .title {
        font-size: 14px;
        line-height: 17px;
        font-weight: 700;
      }
      .desc {
        font-size: 14px;
        line-height: 17px;
        margin-top: 13px;
      }
      ul {
        li {
          font-size: 14px;
          line-height: 22px;
          padding-left: 12px;
        }
      }
    }
  }
}
.section-group-2 {
  display: flex;
  width: 100%;
  height: 557px;
  .img {
    width: 45%;
    height: 100%;
    background-image: url("../images/brand-group-bg.jpg");
    background-size: cover;
    background-position: center;
  }
  .text {
    width: 55%;
    background-color: #fbfbfb;
    .desc {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #161618;
    }
    .msg {
      font-weight: 600;
      font-size: 18px;
      line-height: 32px;
      color: #161618;
      margin-top: 50px;
    }
    @include media-breakpoint-up(lg) {
      padding: 60px 40px;
      .desc {
        font-size: 14px;
        line-height: 24px;
      }
      .msg {
        font-size: 18px;
        line-height: 32px;
        margin-top: 30px;
      }
    }
    @include media-breakpoint-up(xxl) {
      padding: 80px 113px;
    }
  }
  @include media-breakpoint-down(lg) {
    display: block;
    height: auto;
    .img {
      width: 100%;
      height: 250px;
    }
    .text {
      width: 100%;
      padding: 30px 18px 200px;
      background-color: #fbfbfb;
      .desc {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #161618;
      }
      .msg {
        font-weight: 600;
        font-size: 18px;
        line-height: 32px;
        color: #161618;
        margin-top: 32px;
      }
    }
  }
}

//  브랜드 group -- end

.brand-outline {
  .nature-content.result {
    width: 228px;
    height: 228px;
    border: 1px dashed #444444;
    filter: drop-shadow(0px 7px 10px rgba(0, 0, 0, 0.25));
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.visually-hidden-focusable {
  @include media-breakpoint-down(lg) {
    position: absolute;
    top: $header-height-lg-expand;
    left: 0;
  }
}

.outline-content-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include media-breakpoint-down(lg) {
    flex-direction: column;
    gap: 40px;
  }
}
.outline-content-ball {
  width: 336px;
  height: 336px;
  border: 1px dashed #444444;
  filter: drop-shadow(0px 7px 10px rgba(0, 0, 0, 0.25));
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  .box {
    width: 304px;
    height: 304px;
    background-color: #202022;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 35px;
      color: #ffffff;
    }
    .desc {
      font-size: 16px;
      line-height: 24px;
      color: #aaaaaa;
      margin-top: 20px;
    }
  }
  @include media-breakpoint-down(lg) {
    width: 311px;
    height: 311px;
    .box {
      width: 282px;
      height: 282px;
      .title {
        font-size: 22px;
        line-height: 32px;
      }
      .desc {
        line-height: 26px;
      }
    }
  }
}
.brand-outline-content {
  .text-wrap {
    padding: 80px 0 50px;
    p {
      margin-bottom: 30px;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      color: #ffffff;
      &:last-child {
        margin-bottom: 0;
      }
      &.bold {
        font-weight: 700;
        font-size: 18px;
        line-height: 32px;
        color: #dddddd;
        margin-top: 80px;
      }
    }
    @include media-breakpoint-down(lg) {
      p {
        margin-bottom: 30px;
        font-size: 16px;
        line-height: 26px;
        text-align: left;
        &:last-child {
          margin-bottom: 0;
        }
        &.bold {
          font-weight: 700;
          font-size: 18px;
          line-height: 32px;
          color: #dddddd;
          margin-top: 80px;
        }
      }
    }
  }
}
.mt-120 {
  margin-top: 120px;
}
@include media-breakpoint-down(lg) {
  .mt-120 {
    margin-top: 60px;
  }
}
