.brand-section{
  &.emission{
    background-image: url('../images/emission-visual-01.png'); background-size: cover; background-position: center; padding: 180px 0; background-color: transparent;
    .txt-box{
      .page-top-text{
        font-weight: 200; font-size: 66px; line-height: 79px; text-align: center; color: #161618;
        strong{
         font-weight: 500;
        }
      }
      .line{ width: 48px; height: 2px; margin: 80px auto 40px; background: #161618; display: block;}
      .page-top-desc{
        font-size: 18px; line-height: 26px; text-align: center; color: #161618;
      }
    }
    @include media-breakpoint-down(lg){
      background-size: 100%; background-repeat: no-repeat; background-position: center bottom; padding: 0 0 300px 0;
      .txt-box{
        .page-top-text{
          font-size: 26px; line-height: 31px;
        }
        .line{ width: 48px; height: 2px; margin: 50px auto 40px;}
        .page-top-desc{
          font-size: 14px; line-height: 22px;
        }
      }
    }
  }
}
.middle-banner{
  padding: 180px 0 120px;
  
  .middle-banner-desc{
    font-weight: 300; font-size: 34px; line-height: 41px; color: #161618; margin-top: 60px; word-wrap: break-word;
    strong{ font-weight: 700;}
  }
  @include media-breakpoint-down(lg){
    padding: 100px 0 30px;
    .middle-banner-title{ width: 50%;}
    .middle-banner-desc{ font-size: 22px; line-height: 32px; margin-top: 20px;}
  }
}
.section-zero{
  padding: 215px 0;
  
  &.waste{
    width: 100%; min-height: 3788px; position: relative; z-index: 1;
    &::before{
      content: ""; position: absolute; top: 0; left: 0; width: 100%; height: 100%;  background-image: url('../images/zero-waste-01-01.png'); background-size: cover; background-position: center; z-index: -1;
    }
  }
  &.emission{
    width: 100%; min-height: 3324px; position: relative; z-index: 1;
    &::before{
      content: ""; position: absolute; top: 0; left: 0; width: 100%; height: 100%;  background-image: url('../images/zero-waste-02-02.png'); background-size: cover; background-position: center; z-index: -1;
    }
  }
  &.impact{
    width: 100%; min-height: 3030px; position: relative; z-index: 1;
    &::before{
      content: ""; position: absolute; top: 0; left: 0; width: 100%; height: 100%;  background-image: url('../images/zero-waste-03-03.png'); background-size: cover; background-position: center; z-index: -1;
    }
  }
  .zero-txt-wrap{
    margin-top: 145px;
  }
  .txt-box{
    margin-bottom: 92px;
    &:last-child{margin-bottom: 0;}
    .txt-box-title{ font-weight: 700; font-size: 32px; line-height: 38px; color: #161618; font-family: 'Brand New Hyosung OTF'; word-wrap: break-word;}
    .txt-box-desc{ font-size: 18px; line-height: 32px; color: #161618; margin-top: 28px; word-wrap: break-word;}
  }
  @include media-breakpoint-down(lg){
    padding: 130px 0;
    &.waste{
      min-height: 3995px;
      &::before{
        background-image: url('../images/zero-waste-01-mo.png');
      }
    }
    &.emission{
      min-height: 3291px;
      &::before{
        background-image: url('../images/zero-waste-02-mo.png');
      }
    }
    &.impact{
      min-height: 3017px;
      &::before{
        background-image: url('../images/zero-waste-03-mo.png');
      }
    }
    .icon-resources{ width: 105px; height: 105px;  background-image: url('../images/icon-resources-white.svg');}
    .icon-emission{ width: 100px; height: 100px;  background-image: url('../images/icon-emission-white.svg');}
    .icon-impact{ width: 120px; height: 100px;}

    .zero-txt-wrap{
      margin-top: 65px;
    }
    .txt-box{
      margin-bottom: 100px;
      &:last-child{margin-bottom: 0;}
      .txt-box-title{ font-size: 22px; line-height: 32px; text-align: center; color: #fff;}
      .txt-box-desc{ font-size: 16px; line-height: 26px; margin-top: 30px; color: #fbfbfb;}
    }
  }
}
.zero-item-wrap{
  margin-top: 240px;
  @include media-breakpoint-down(lg){
    margin-top: 120px;
  }
}
.zero-item-list{
  .zero-item-box{
    margin-bottom: 160px;
    &:last-child{margin-bottom: 0;}

    .title{font-weight: 600; font-size: 28px; line-height: 38px; color: #161618; font-family: 'Brand New Hyosung OTF'; word-wrap: break-word;}
    .zero-item-card{
      padding: 20px 20px 30px 20px; background-color: rgba(22, 22, 24, 0.7); border-radius: 4px; margin-top: 28px; 
      .card-img{
        img{width: 100%; height: 100%; object-fit: cover;}
      }
    }
    .card-desc{
      font-size: 18px; line-height: 32px; color: #FBFBFB; margin-top: 30px; word-wrap: break-word;
    }
  }
  &.right{
    margin-top: 370px;
  }

  @include media-breakpoint-down(lg){
    &.right{
      margin-top: 90px;
    }
    .zero-item-box{
      margin-bottom: 90px;
      .title{font-size: 22px; line-height: 32px; color: #fff;}
      .zero-item-card{
        padding: 16px 16px 30px 16px; margin-top: 20px; 
      }
      .card-desc{
        font-size: 16px; line-height: 26px; margin-top: 20px;
      }
    }
  }
}

// 탄소재료
.tansome-wrap{

  .product-features-3{
    padding: 180px 0 300px; background-color: #161618;
    @include media-breakpoint-down(lg){
      padding: 100px 0;
    }
    .product-features-list{
      .img{
        aspect-ratio: 658/320; max-width: 658px;
        img{width: 100%;}
      }
      @include media-breakpoint-down(lg){
        .img{
          margin-left: 0;
        }
      }
    }
    &.tansome-features-1{
      background-color: #fff;  padding: 160px 0 240px;
      .product-features-title{
        span{font-weight: 700; font-size: 48px; line-height: 57px; color: #161618; }
      }
      .product-features-desc{
        font-size: 18px; line-height: 32px; color: #333333;
      }
      .product-features-list{
        li{ 
          display: flex; align-items: center; justify-content: space-between;
          .txt-box{
            flex: calc(100% - 276px - 15.364916%);
          }
          .txt-box-title{font-weight: 600; font-size: 28px; line-height: 38px; color: #161618; font-family: 'Brand New Hyosung OTF';}
          .txt-box-desc{font-size: 18px; line-height: 32px; color: #333333; margin-top: 10px;}
          .txt-box-img{
            aspect-ratio: 276/200; max-width: 276px;
            &.case-1 {
              position: relative;
              border: 1px solid #DDDDDD;
              overflow: hidden;
            }
            img{width: 100%;}
          }
        }
      }
    }
    @include media-breakpoint-down(lg){
      &.tansome-features-1{
        padding: 100px 0 0 0;
        .product-features-title{
         span{ font-size: 28px; line-height: 36px; margin-bottom: 20px;}
        }
        .product-features-desc{
          font-size: 16px; line-height: 26px;
          p{margin-bottom: 14px;
            &:last-child{margin-bottom: 0;}
          }
        }
        .product-features-list{
          ul{ gap: 60px; }
          li{ 
            display: flex; align-items: inherit; flex-direction: column; gap: 30px; margin-right: -18px;
            .txt-box{
              flex: 100% 0; order: 2;
            }
            .txt-box-title{font-size: 18px; line-height: 22px;}
            .txt-box-desc{font-size: 16px; line-height: 26px; padding-right: 18px;}
            .txt-box-img{
              width: 100%; height: 120px; max-width: 100%; order: 1;
              
              img{width: 100%; height: 100%; object-fit: cover;}

              &.case-1 {
                border-right: none;
                
              }
            }
          }
        }
      } 
    }
    &.alkex-features-1{
      background-color: #fff; padding: 0 0 80px 0;
      .product-features-title{
        span{font-weight: 700; font-size: 48px; line-height: 57px; color: #161618; }
      }
      .product-features-desc{
        font-size: 18px; line-height: 32px; color: #333333;
      }
      .product-features-body{
        margin-top: 100px;
      }
      .alkex-features-history{
        margin-top: 120px;
        p{
          font-size: 34px; line-height: 46px; color: #777777; margin-bottom: 40px;
          &:last-child{margin-bottom: 0;}
          span{ font-weight: 600; color: #161618;}
        }
      }
      @include media-breakpoint-down(lg){
        padding: 100px 0 0 0;
        .product-features-title{
          span{ font-size: 28px; line-height: 36px; margin-bottom: 20px;}
          }
          .product-features-desc{
            font-size: 16px; line-height: 26px;
            p{margin-bottom: 14px;
              &:last-child{margin-bottom: 0;}
            }
          }
          .product-features-body{
          margin-top: 60px;
        }
        .alkex-features-history{
          margin-top: 40px;
          p{
            font-size: 20px; line-height: 32px; margin-bottom: 16px;
          }
        }
      }
    }
  }
  section{
    padding: 160px 0 240px;
    @include media-breakpoint-down(lg){
      padding: 100px 0;
    }
  }
  
}
.right-bg-img{
  position: relative; height: 420px;
  &::after{
    content:""; position: absolute; background-image: url('../images/yarn-6-1.jpg'); background-size: cover; background-position: center; width: 100vw; height: 100%; background-repeat: no-repeat;
  }
  &.alkex{
    &::after{
      background-image: url('../images/alkex-6-1.jpg');
    }
  }
  &.yarn{
    &::after{
      background-image: url('../images/yarn-6-1.jpg');
    }
  }
  &.carpet{
    &::after{
      background-image: url('../images/carpet-6-1.jpg');
    }
  }
  @include media-breakpoint-down(lg){
    height: 250px;
    &::after{
      background-position: left;
    }
  }
}

// 추가
.col-12 {
  &.col-lg-5 {
    &.case-a {
      width: 40.79341%;

      @include media-breakpoint-down(lg){
        width: 100%;
      }
    }
  }
}