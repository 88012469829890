
.form-control {
    display: block;
    width: 100%;
    padding: 11px 10px;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: #4d4d4d;
    background-color: #fff;
    border: 1px solid #ccc;

    &.white{ background-color: #fff; border: 1px solid #ddd;}
    &::placeholder{ color: #999;}

    &:focus {
      outline: 1px solid #000;
    }
  
    &:disabled {
      background-color: #e9ecef;
    }
    &.form-sm{ max-height: 40px; padding: 9px 30px 8px 30px; font-size: 14px;}
    &.form-md{ max-height: 48px;  padding: 11px 30px 11px 30px; font-size: 16px;}
    &.form-lg{ max-height: 60px;  padding: 17px 30px 17px 30px; font-size: 18px;}
  }

  .form-upload{
    display: block;
    width: 100%;
    min-width: 170px;
    padding: 9px 24px;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
    color: #777777;
    background-color: #f8f8fa;
    border-radius: 4px;
    border:0;
    input{display: none;}
    label{
      width: 100%; text-align: right; cursor: pointer;
    }
  }
  .date-range{
    display: flex; align-items: center;
    span{margin: 0 10px;}
    .form-date-wrap{padding: 9px 33px 9px 10px;}
  }
  .form-date-wrap{
    width: 140px; padding: 4px 33px 4px 5px;
    border: 1px solid #ddd;
    .form-date{ border:0; width: 100%;}
  }
  @media screen and (max-width: 992px) {
    .form-control {
      // &.form-sm{ min-height: 22px; font-size: 11px; padding: 4px 15px 4px 12px;}
      // &.form-md{min-height: 24px; font-size: 12px; padding: 8px 15px 8px 12px;}
      // &.form-lg{min-height: 26px; font-size: 13px;  padding: 12px 15px 12px 12px;}
    }
  }