.brand-section {
  background-color: $dark-gray-2;
  padding: 50px 0;
  width: 100%;
  overflow: hidden;
  position: relative;
  @include media-breakpoint-up(lg) {
    padding: 130px 0;
    min-height: calc(100vh - 120px);
  }
}

// -------------------------------- 브랜드저널 start
.brand-journal {
  margin-top: 40px;
  .swiper-slide {
    padding-bottom: 115px;
  }
  .swiper-button-wrap {
    width: 115px;
    height: 50px;
    position: absolute;
    bottom: 40px;
    left: 30%;
  }
  .swiper-button-prev {
    width: 48px;
    height: 48px;
    opacity: 1 !important;
    left: 0;
    &::after {
      content: none;
    }
  }
  .swiper-button-next {
    width: 48px;
    height: 48px;
    opacity: 1 !important;
    right: 0;
    &::after {
      content: none;
    }
  }
}

.brand-section .brand-journal .banner-img {
  position: relative;
  width: 74.625%;
  height: 0;
  padding-bottom: 40.419%;
  overflow:hidden;
  img {
    display:block;
    width:100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%,-50%);
  }
  @include media-breakpoint-down(lg){
    width: 100%;
    padding-bottom: 50.666%;
  }
}
.desc-content {
  width: 770px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 10;
  background: rgba(22, 22, 24, 0.86);
  .desc-content-wrap {
    display: block;
    padding: 60px 45px 40px 90px;
    width: 100%;
    height: 100%;
    &:hover {
      .btn.round-btn.plus {
        color: $white !important;
        background-color: $primary !important;
        border-color: $primary !important;
        .icon-plus {
          background-image: url("../images/icon-w-plus.svg");
        }
      }
    }
    @include media-breakpoint-down(lg){
      padding: 0 40px 40px 0;
    }
  }
  .date {
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    color: $white;
  }
  .title {
    font-size: 32px;
    line-height: 38px;
    font-weight: 600;
    color: $white;
    margin-top: 1.5rem;
    @include ellipsis(2);
    width: 90%;
    word-break: keep-all;
    word-wrap: break-word;
    @include media-breakpoint-down(lg){
      width: 100%;
    }
  }
  .text {
    line-height: 29px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.7);
    margin-top: 3rem;
    @include ellipsis(2);
    width: 90%;
    word-break: keep-all;
    word-wrap: break-word;
    @include media-breakpoint-down(lg){
      width: 100%;
    }
  }
  .more {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    .counter {
      span {
        font-size: 13px;
        line-height: 16px;
        color: rgba(255, 255, 255, 0.5);
      }
      .current-slide {
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }
}
@include media-breakpoint-down(lg) {
  .brand-journal {
    margin-top: 40px;
    .swiper-slide {
      padding-bottom: 0px;
    }
    .swiper-button-wrap {
      width: 115px;
      height: 50px;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  .desc-content {
    width: 100%;
    position: static;
    padding: 30px 0 0 0;
    background-color: $dark-gray-2;
    .date {
      font-size: 14px;
      line-height: 17px;
      color: $gray-1;
    }
    .title {
      font-size: 22px;
      line-height: 30px;
      font-weight: 700;
      margin-top: 1.3rem;
      margin-bottom: 70px;
    }
    .text {
      display: none;
    }
    .more {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 1rem;
      .btn {
        display: none;
      }
      .counter {
        position: absolute;
        bottom: 12px;
        right: 25px;
        span {
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
  }
}
.various {
  background-color: $light-gray-3;
  > .container {
    transform: translate(30px, 30px);
  }
  .container {
    position: relative;
    z-index: 10;
  }
  @include media-breakpoint-up(lg) {
    padding: 90px 0 180px;
    .title {
      font-weight: 700;
      font-size: 66px;
      line-height: 72px;
      color: $dark-gray-1;
    }
    .desc {
      font-size: 60px;
      line-height: 63px;
      color: $dark-gray-1;
    }
    .various-content {
      margin-top: 80px;
      li {
        position: relative;
        width: 100%;
        height: 100%;
        margin-bottom: 27px;
        overflow: hidden;
        .img {
          width: 100%;
          height: 0;
          padding-bottom: 56.179%;
          position: relative;
          overflow: hidden;
          img {
            display:block;
            width:100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 50%; left: 50%;
            transform: translate(-50%,-50%);
          }
        }
        .con-bg {
          width: 0%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 33%;
          background-color: $white;
        }
      }
    }
    .box {
      padding: 5.3% 60px;
      .date {
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        color: $dark-gray-2;
      }
      .card-title {
        font-size: 24px;
        line-height: 28px;
        font-weight: 700;
        color: $dark-gray-2;
        margin-top: 1.5rem;
        @include ellipsis(1);
        word-break: keep-all;
        word-wrap: break-word;
      }
      .card-text {
        line-height: 26px;
        color: rgba(0, 0, 0, 0.7);
        line-height: 19px;
        margin-top: 2rem;
        @include ellipsis(2);
        word-break: keep-all;
        word-wrap: break-word;
      }
    }
    .navigation {
      margin-top: 50px;
    }
    .img {
      height: 100%;
      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    padding: 50px 0 200px;
    .title {
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      color: $dark-gray-1;
    }
    .desc {
      font-size: 32px;
      line-height: 38px;
      color: $dark-gray-1;
    }
    .various-content {
      margin-top: 40px;
    }
    .row {
      margin-bottom: 10px;
    }
    .box {
      background-color: $white;
      padding: 20px 20px 45px;
      .date {
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        color: $dark-gray-2;
      }
      .card-title {
        font-size: 24px;
        line-height: 30px;
        font-weight: 700;
        color: $dark-gray-2;
        margin-top: 1.2rem;
        @include ellipsis(2);
      }
      .card-text {
        line-height: 26px;
        color: $dark-gray-3;
        margin-top: 1.5rem;
        @include ellipsis(4);
      }
    }
    .navigation {
      margin-top: 12px;
    }
    .img {
      width: 100%;
      height: 0;
      padding-bottom: 56.179%;
      position: relative;
      overflow: hidden;
      img {
        display:block;
        width:100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%,-50%);
      }
    }
  }
}
// -------------------------------- 브랜드저널 end

// -------------------------------- 브랜드 TV start
.brand-tv {
  //overflow: visible !important;
  .swiper-pagination {
    width: auto;
    top: inherit !important;
    bottom: 0;
    height: 2px !important;
    left: 0 !important;
    right: 0;
  }
  .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background: #ff2d2d;
  }
}
.thumbnail {
  .swiper-slide {
    opacity: 0.4;
    &.swiper-slide-thumb-active {
      opacity: 1;
    }
  }
}
@include media-breakpoint-up(lg) {
  .brand-tv-wrap {
    margin-top: 90px;
    position: relative;
    > .row:first-of-type {
      margin-bottom: 110px;
    }
    .col-lg-2.position-relative {
      z-index: 1;
      background-color: $dark-gray-2;
    }
    .brand-tv-banner {
      padding-top: 40px;
    }
    .banner-number {
      font-weight: 700;
      font-size: 12px;
      line-height: 19px;
      color: $white;
    }
    .banner-title {
      font-weight: 700;
      font-size: 46px;
      line-height: 73px;
      color: $white;
      font-family: 'Brand New Hyosung OTF';
      @include ellipsis(2);
    }
    .banner-desc {
      font-size: 16px;
      line-height: 26px;
      margin-top: 2.5rem;
      color: $white;
      @include ellipsis(4);
      width: 70%;
      word-break: keep-all;
      word-wrap: break-word;
      @include media-breakpoint-down(lg){
        width: 100%;
      }
    }
    .swiper-button-wrap {
      .swiper-button-prev {
        width: 48px;
        height: 48px;
        opacity: 1 !important;
        left: -75px;
        margin-top: -24px;
        &::after {
          content: none;
        }
      }
      .swiper-button-next {
        width: 48px;
        height: 48px;
        opacity: 1 !important;
        right: -75px;
        margin-top: -24px;
        &::after {
          content: none;
        }
      }
    }
  }
  .brand-tv {
    .swiper-slide {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 56.231%;
      overflow:hidden;
      img {
        display:block;
        width:100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%,-50%);
      }
    }
  }
  .thumbnail {
    .swiper-slide {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 13.323%;
      overflow:hidden;
      img {
        display:block;
        width:100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%,-50%);
      }
      .thumbnail-desc {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 500;
        color: $white;
        font-size: 16px;
      }
    }
  }
}
@include media-breakpoint-down(lg) {
  .brand-tv-wrap {
    margin-top: 40px;
    position: relative;
    > .row:first-of-type {
      margin-bottom: 70px;
    }
    .brand-tv-banner {
      padding-top: 40px;
    }
    .banner-number {
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      color: $white;
    }
    .banner-title {
      font-weight: 700;
      font-size: 32px;
      line-height: 30px;
      color: $white;
      margin-top: 0.5rem;
    }
    .banner-desc {
      font-size: 14px;
      line-height: 22px;
      margin-top: 2rem;
      color: $white;
    }
    .swiper-button-wrap {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 12px;
      margin-top: 20px;
      .swiper-button-prev,
      .swiper-button-next {
        width: 48px;
        height: 48px;
        opacity: 1 !important;
        right: 0;
        position: static;
        margin-top: 0;
        &::after {
          content: none;
        }
      }
    }
  }
  .brand-tv-area {
    margin-left: -18px;
    margin-right: -18px;
  }
  .brand-tv {
    .swiper-slide {
      aspect-ratio: 375/210;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .swiper-pagination {
      left: 75px !important;
      right: 75px !important;
      width: auto !important;
      bottom: 30px;
      height: 4px !important;
      background: rgba(255, 255, 255, 0.4) !important;
      border-radius: 8px;
      overflow: hidden;
    }
  }
  .thumbnail-area {
    margin-left: -18px;
    margin-right: -18px;
  }
  .thumbnail {
    .swiper-slide {
      position: relative;
      width: 100%;
      width: 124px;
      height: 69px;
      // padding-bottom: 13.323%;
      overflow:hidden;
      img {
        display:block;
        width:100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%,-50%);
      }
      .thumbnail-desc {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 500;
        color: $white;
        font-size: 12px;
        width: 100%;
        text-align: center;
        padding: 0 5px;
      }
    }
  }
}

.media {
  .container {
    position: relative;
    z-index: 10;
  }
  @include media-breakpoint-up(lg) {
    padding: 90px 0 180px;
    .title {
      font-weight: 700;
      font-size: 66px;
      line-height: 72px;
      color: $dark-gray-1;
      font-family: "Brand New Hyosung OTF";
    }
    .media-content {
      display: flex;
      flex-flow: wrap;
      gap: 20px;
      li {
        position: relative;
        width: calc(50% - 10px);
        height: 100%;
        margin-bottom: 110px;
      }
    }
    .box {
      padding: 10px 80px 0 0;
      .card-title {
        font-size: 20px;
        line-height: 32px;
        font-weight: 700;
        color: $dark-gray-3;
        margin-top: 1.5rem;
        @include ellipsis(1);
      }
      .card-text {
        color: $gray-3;
        line-height: 19px;
        margin-top: 0.75rem;
        @include ellipsis(2);
      }
    }
    .navigation {
      margin-top: 50px;
    }
    .img {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 56.561%;
      overflow:hidden;
      img {
        display:block;
        width:100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%,-50%);
      }
      .hashtag-list {
        position: absolute;
        bottom: 20px;
        right: 20px;
        color: #fff;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    padding: 50px 0 200px;
    .title {
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      color: $dark-gray-1;
    }
    .desc {
      font-size: 32px;
      line-height: 38px;
      color: $dark-gray-1;
    }
    .media-content {
      margin-top: 40px;
      li {
        margin-bottom: 66px;
      }
    }
    .row {
      margin-bottom: 10px;
    }
    .box {
      padding-right: 40px;
      .card-title {
        font-size: 20px;
        line-height: 26px;
        font-weight: 700;
        color: $dark-gray-2;
        margin-top: 1.2rem;
        @include ellipsis(2);
      }
      .card-text {
        font-size: 14px;
        line-height: 22px;
        color: $dark-gray-3;
        margin-top: 1rem;
        @include ellipsis(3);
      }
    }
    .navigation {
      margin-top: 12px;
    }
    .img {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 56.561%;
      overflow:hidden;
      img {
        display:block;
        width:100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%,-50%);
      }
      .hashtag-list {
        position: absolute;
        bottom: 20px;
        right: 20px;
        color: #fff;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
}

.no-data {
  text-align: center;
  padding: 30px 0;
  p {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: $dark-gray-2;
    margin-top: 1.5rem;
  }
}
// -------------------------------- 브랜드 TV end

// -------------------------------- 브랜드이미지 start
.brand-image-wrap {
  display: flex;
  justify-content: center;
  margin-left: 0;
  padding-left: 0;
  margin-top: 200px;
  .brand-main-swiper {
    width: calc(50vw + 103px);
    display: flex;
    justify-content: right;
    .hide-swipe {
      width: 44vw;
      opacity: 0;
    }
    img {
      width: 100%;
    }
    .progress-bar {
      content: "";
      position: absolute;
      width: 44vw;
      height: 2px;
      right: 0;
      bottom: 0;
      z-index: 10;
      .per {
        position: absolute;
        right: 0;
        height: 100%;
        background-color: $red;
        transition: width 1s ease;
      }
    }
  }
  .brand-swipe-wraper {
    width: calc(50vw + 103px);
    position: absolute;
    right: 0;
    top: 0;
    .brand-swipe {
      &.active {
        filter: grayscale(0);
        transition: all 1s ease;
        transition-property: right, filter;
      }
      position: absolute;
      top: 0;
      width: calc(44vw);
      filter: grayscale(100%);
      z-index: 1;
      transition: all 0.9s ease;
      transition-property: right, filter;
      .img-wrap {
        position: relative;
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: 56.137%;
        overflow:hidden;
        img {
          display:block;
          width:100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          top: 50%; left: 50%;
          transform: translate(-50%,-50%);
        }
      }
    }
    img {
      width: 100%;
    }
  }

  .image-desc-content {
    position: relative;
    .box {
      position: absolute;
      top: 0;
      padding: 0;
      background-color: $dark-gray-2;
      opacity: 0;
      z-index: 1;
      transition: all 0.8s ease;
      &.active {
        position: relative;
        opacity: 1;
        z-index: 2;
      }
    }
    .title {
      font-size: 42px;
      line-height: 130%;
      color: $white;
      font-weight: 700;
      @include ellipsis(2);
      word-break: keep-all;
      word-wrap: break-word;
    }
    .text {
      color: $white;
      line-height: 150%;
      margin-top: 1.7rem;
      @include ellipsis(3);
      word-break: keep-all;
      word-wrap: break-word;
    }
    .more {
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 1rem;
      position: absolute;
      bottom: 0;
      margin-bottom: -150px;
      .counter {
        font-weight: 600;
        padding-left: 12px;
        span {
          font-size: 13px;
          line-height: 16px;
          color: rgba(255, 255, 255, 0.5);
        }
        .current-slide {
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }
    .swiper-button-wrap {
      width: 115px;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .swiper-button-prev {
      width: 48px;
      height: 48px;
      opacity: 1 !important;
      left: 0;
      &::after {
        content: none;
      }
    }
    .swiper-button-next {
      width: 48px;
      height: 48px;
      opacity: 1 !important;
      right: 0;
      &::after {
        content: none;
      }
    }
  }
}
@include media-breakpoint-up(lg) {
  .brand-image-wrap {
    display: flex;
    justify-content: center;
    margin-left: 0;
    padding-left: 0;
    margin-top: 200px;
    .brand-image {
      .swiper-wrapper {
        gap: 100px;
      }
      .swiper-slide {
        width: 90px;
        height: 480px;
        animation: removeSlide 0.5s linear;
        // transform: translateX(-370px);
        .banner-img {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter: grayscale(100%);
          }
        }
        &.swiper-slide-active {
          width: 100%;
          animation: addSlide 0.5s linear;
          // transform: translateX(0);
          .banner-img {
            img {
              filter: inherit;
            }
          }
        }
      }
      @keyframes addSlide {
        0% {
          width: 90px;
          object-fit: cover;
        }
        100% {
          width: 100%;
          object-fit: cover;
        }
      }
      @keyframes removeSlide {
        0% {
          width: 100%;
          object-fit: cover;
        }
        100% {
          width: 90px;
          object-fit: cover;
        }
      }
    }
    .hide-swiper {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      .swiper-slide {
        .banner-img {
          width: 100%;
          height: 100%;
          opacity: 0;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
  .image {
    padding: 140px 0;
    .normal-type {
      margin: 1rem 0 40px;
      justify-content: flex-end;
    }
    .image-content {
      li {
        margin-bottom: 100px;
      }
    }
    .title {
      color: $dark-gray-1;
      font-size: 66px;
      line-height: 72px;
      font-weight: 700;
      font-family: "Brand New Hyosung OTF";
    }
    .img {
      width: 100%;
      position: relative;
      img {
        width: 100%;
      }
      &.type-1 {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 55.775%;
        overflow:hidden;
        img {
          display:block;
          width:100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          top: 50%; left: 50%;
          transform: translate(-50%,-50%);
        }
      }
      .in-box {
        position: absolute;
        bottom: 40px;
        left: 60px;
        .card-title {
          color: $white;
          font-size: 20px;
          line-height: 32px;
          font-weight: 700;
          @include ellipsis(1);
          word-break: keep-all;
          word-wrap: break-word;
        }
        .date {
          font-size: 12px;
          color: $white;
          line-height: 14px;
          margin-top: 0.5rem;
          font-weight: 500;
          @include ellipsis(2);
          word-break: keep-all;
          word-wrap: break-word;
        }
        .card-text {
          color: #d0d0d0;
          font-size: 16px;
          line-height: 19px;
          margin-top: 1.5rem;
          @include ellipsis(2);
          word-break: keep-all;
          word-wrap: break-word;
        }
      }
    }
    .box {
      padding: 24px 0 0 0;
      .card-title {
        margin-top: 0.675rem;
        @include ellipsis(2);
        word-break: keep-all;
        word-wrap: break-word;
        width: 90%;
        @include media-breakpoint-down(lg){
          width: 100%;
        }
      }
    }
  }
}
@include media-breakpoint-down(lg) {
  .brand-image-wrap {
    margin-top: 40px;
    .container {
      padding-left: 0px;
      padding-right: 0px;
    }
    .brand-main-swiper {
      width: 100vw;
      display: flex;
      justify-content: right;
      .hide-swipe {
        width: 100vw;
      }
      .progress-bar {
        left: 20vw;
        height: 4px;
        bottom: 30px;
        width: 60vw;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 8px;
        overflow: hidden;
        .per {
          position: absolute;
          right: 0;
          height: 100%;
          background-color: $red;
          transition: width 1s ease;
        }
      }
    }
    .brand-swipe-wraper {
      width: 100vw;
      .brand-swipe {
        width: 100vw;
      }
    }

    .image-desc-content {
      position: relative;
      .box {
        padding: 40px 18px 0;
      }
      .title {
        font-size: 24px;
        line-height: 30px;
      }
      .text {
        font-size: 14px;
        line-height: 22px;
      }
      .more {
        padding: 0 18px;
        position: relative;
        bottom: 0;
        margin-bottom: 0;
        height: auto;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 55px;
      }
      .swiper-button-wrap {
        position: relative;
        display: flex;
        height: 48px;
        margin-top: 20px;
      }
    }
  }
  .image {
    padding: 50px 0 200px;
    .normal-type {
      margin: 30px 0 40px;
      justify-content: flex-end;
      gap: 20px;
    }
    .image-content {
      .col-12 {
        margin-bottom: 60px;
      }
    }
    .title {
      color: $dark-gray-1;
      font-size: 32px;
      line-height: 38px;
      font-weight: 700;
    }
    .img {
      width: 100%;
      position: relative;
      img {
        width: 100%;
      }
      .in-box {
        position: absolute;
        bottom: 40px;
        left: 60px;
        .card-title {
          color: $white;
          font-size: 20px;
          line-height: 32px;
          font-weight: 700;
        }
        .date {
          font-size: 12px;
          color: $white;
          line-height: 14px;
          margin-top: 0.5rem;
          font-weight: 500;
        }
        .card-text {
          color: #d0d0d0;
          font-size: 16px;
          line-height: 19px;
          margin-top: 1.5rem;
        }
      }
    }
    .image {
      padding: 140px 0;
      .normal-type {
        margin: 1rem 0 40px;
        justify-content: flex-end;
      }
      .image-content {
        li {
          margin-bottom: 100px;
        }
      }
      .title {
        color: $dark-gray-1;
        font-size: 66px;
        line-height: 72px;
        font-weight: 700;
      }
      .img {
        width: 100%;
        position: relative;
        img {
          width: 100%;
        }
        .in-box {
          position: absolute;
          bottom: 40px;
          left: 60px;
          .card-title {
            color: $white;
            font-size: 20px;
            line-height: 32px;
            font-weight: 700;
          }
          .date {
            font-size: 12px;
            color: $white;
            line-height: 14px;
            margin-top: 0.5rem;
            font-weight: 500;
          }
          .card-text {
            color: #d0d0d0;
            font-size: 16px;
            line-height: 19px;
            margin-top: 1.5rem;
          }
        }
      }
      .box {
        padding: 24px 0 0 0;
        .card-title {
          margin-top: 0.675rem;
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .brand-image-wrap {
      margin-top: 40px;
      .container {
        padding-left: 0px;
        padding-right: 0px;
      }
      .brand-main-swiper {
        width: 100vw;
        display: flex;
        justify-content: right;
        .hide-swipe {
          width: 100vw;
        }
        .progress-bar {
          left: 20vw;
          height: 4px;
          bottom: 30px;
          width: 60vw;
          background: rgba(255, 255, 255, 0.2);
          border-radius: 8px;
          overflow: hidden;
          .per {
            position: absolute;
            left: 0;
            right: auto;
            height: 100%;
            background-color: $red;
            transition: width 1s ease;
          }
        }
      }
      .brand-swipe-wraper {
        width: 100vw;
        .brand-swipe {
          width: 100vw;
          transition-property: left, filter;
          &.active {
            transition-property: left, filter;
          }
        }
      }

      .image-desc-content {
        position: relative;
        .box {
          padding: 40px 18px 0;
        }
        .title {
          font-size: 24px;
          line-height: 30px;
          @include ellipsis(2);
        }
        .text {
          font-size: 14px;
          line-height: 22px;
          @include ellipsis(4);
        }
        .more {
          padding: 0 18px;
          position: relative;
          bottom: 0;
          margin-bottom: 0;
          height: auto;
          flex-direction: column;
          align-items: flex-start;
          margin-top: 55px;
          .counter {
          }
        }
        .swiper-button-wrap {
          position: relative;
          display: flex;
          height: 48px;
          margin-top: 20px;
        }
      }
    }
    .image {
      padding: 50px 0 200px;
      .normal-type {
        margin: 30px 0 40px;
        justify-content: flex-end;
      }
      .image-content {
        .col-12 {
          margin-bottom: 60px;
        }
      }
      .title {
        color: $dark-gray-1;
        font-size: 32px;
        line-height: 38px;
        font-weight: 700;
      }
      .img {
        width: 100%;
        position: relative;
        &.type-1 {
          position: relative;
          width: 100%;
          height: 0;
          padding-bottom: 55.775%;
          overflow:hidden;
          img {
            display:block;
            width:100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 50%; left: 50%;
            transform: translate(-50%,-50%);
          }
        }
        img {
          width: 100%;
        }
        .in-box {
          position: absolute;
          bottom: 40px;
          left: 60px;
          .card-title {
            color: $white;
            font-size: 20px;
            line-height: 32px;
            font-weight: 700;
          }
          .date {
            font-size: 12px;
            color: $white;
            line-height: 14px;
            margin-top: 0.5rem;
            font-weight: 500;
          }
          .card-text {
            color: #d0d0d0;
            font-size: 16px;
            line-height: 19px;
            margin-top: 1.5rem;
          }
        }
      }
      .box {
        padding: 20px 0 0 0;
        .card-title {
          color: $dark-gray-1;
          margin-top: 0.675rem;
          font-size: 20px;
          line-height: 26px;
          font-weight: 700;
          @include ellipsis(2);
        }
        .date {
          font-size: 14px;
          line-height: 16px;
          font-weight: 500;
        }
      }
    }
  }
}

// -------------------------------- 브랜드이미지 end

// -------------------------------- 소셜미디어 start
.social-section {
  background-color: $dark-gray-2;
  padding-top: 130px;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 150px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    z-index: 10;
  }
}
.social-media-wrap {
  @include media-breakpoint-up(lg) {
    .social-media-swiper {
      margin-top: 2.7rem;
      height: 780px;
      position: relative;
      .social-media {
        height: 100%;
        .swiper-pagination {
          height: 2px;
          top: 400px;
          right: 0;
          width: 712px;
          left: auto;
          bottom: 0;
        }
        .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
          background: #ff2d2d;
        }

        .swiper-slide {
          filter: grayscale(100%);
          background: $dark-gray-2;
          > .row {
            border-bottom: 2px solid $dark-gray-1;
            justify-content: space-between; //230705 홍수기 추가
          }
          .banner-title {
            font-weight: 700;
            font-size: 42px;
            line-height: 125%;
            color: rgba(255, 255, 255, 0.3);
            font-family: 'Brand New Hyosung OTF';
            @include ellipsis(2);
          }
          .banner-desc {
            font-size: 16px;
            line-height: 24px;
            color: rgba(255, 255, 255, 0.3);
            margin-top: 1.7rem;
            @include ellipsis(3);
          }
        }
        .swiper-slide-active {
          filter: inherit;
          .banner-title {
            color: $white;
          }
          .banner-desc {
            color: #aaa;
          }
          .img-box::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 151px;
            left: 0;
            bottom: 0;
            background: linear-gradient(
              180deg,
              rgba(0, 0, 0, 0) 0%,
              #000000 100%
            );
          }
        }
      }
      .col-12.col-lg-4 {
        width: 37% !important;
      }
      .box {
        padding: 0;
      }
      .banner-img {
        text-align: right;
        display: block;
        width: 100%;
        height: 100%;
        .img-box {
          position: relative;
          width: 100%;
          height: 0;
          padding-bottom: 58.054%;
          overflow:hidden;
          img {
            display:block;
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
          }
          i {
            position: absolute;
            left: 30px;
            bottom: 37px;
          }
        }
      }
    }
    .more {
      position: absolute;
      // bottom: 52%;
      bottom: auto; //230705 홍수기 추가
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 1;
      margin-top: -71px;
      width: 36% !important;
      .counter {
        span {
          font-size: 13px;
          line-height: 16px;
          color: rgba(255, 255, 255, 0.5);
        }
        .current-slide {
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }
    .swiper-button-wrap {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      width: auto;
      gap: 20px;
      .swiper-button-prev,
      .swiper-button-next {
        width: 48px;
        height: 48px;
        opacity: 1 !important;
        right: 0;
        transform: rotate(90deg);
        position: static;
        margin-top: 0;
        &::after {
          content: none;
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .social-media-swiper {
      margin-top: 40px;
      position: relative;
      .social-media {
        .swiper-pagination {
          height: 2px;
          position: relative;
          top: auto !important;
          width: 100% !important;
        }
        .swiper-pagination-progressbar {
          background: $dark-gray-2;
          .swiper-pagination-progressbar-fill {
            background: #ff2d2d;
          }
        }
        .swiper-slide {
          height: auto !important;
          background: $dark-gray-2;
          .banner-title {
            min-height: 60px;

            font-weight: 700;
            font-size: 22px;
            line-height: 30px;
            color: $white;
            @include ellipsis(2);
          }
          .banner-desc {
            font-size: 16px;
            line-height: 26px;
            color: rgba(255, 255, 255, 0.8);
            margin-top: 1.2rem;
            margin-bottom: 20px; //230705 홍수기 추가
            height: 52px;
            @include ellipsis(2);
          }
        }
      }
      .box {
        padding: 0;
      }
      .banner-img {
        text-align: right;
        display: block;
        width: 100%;
        height: 100%;
        margin-top: 1.3rem;
        .img-box {
          position: relative;
          width: 100%;
          height: 0;
          padding-bottom: 58.054%;
          overflow:hidden;
          img {
            display:block;
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
          }
          i {
            position: absolute;
            left: 30px;
            bottom: 37px;
          }
        }
      }
    }
    .more {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      margin-top: 30px;
      .counter {
        padding-right: 20px;
        span {
          font-size: 14px;
          line-height: 16px;
          color: rgba(255, 255, 255, 0.5);
        }
        .current-slide {
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }
    .swiper-button-wrap {
      display: flex;
      align-items: center;
      width: auto;
      gap: 12px;
      .swiper-button-prev,
      .swiper-button-next {
        width: 48px;
        height: 48px;
        opacity: 1 !important;
        transform: rotate(90deg);
        position: static;
        margin-top: 0;
        &::after {
          content: none;
        }
      }
    }
  }
}
@include media-breakpoint-down(lg) {
  .social-section {
    padding: 50px 0;
    &::after {
      content: none;
    }
  }
}

.social {
  @include media-breakpoint-up(lg) {
    padding: 90px 0 130px;
    .title {
      font-weight: 700;
      font-size: 66px;
      line-height: 72px;
      color: $dark-gray-1;
      font-family: "Brand New Hyosung OTF";
    }
    .social-content {
      margin: 80px 0;
      .img {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        overflow:hidden;
        img {
          display:block;
          width:100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          top: 50%; left: 50%;
          transform: translate(-50%,-50%);
        }
        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 40%;
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            #000000 100%
          );
          z-index: 10;
        }
        i {
          position: absolute;
          left: 30px;
          bottom: 30px;
          z-index: 11;
        }
      }
    }
    .card-content {
      .card-box {
        padding: 40px 30px;
        background-color: $light-gray-3;
        .card-title {
          color: $dark-gray-1;
          font-size: 20px;
          line-height: 24px;
          font-weight: 700;
          height: 48px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .date {
          font-size: 12px;
          font-weight: 500;
          color: rgba(22, 22, 24, 0.5);
          margin-top: 1rem;
        }
      }
    }
    .social-content {
      li {
        margin-bottom: 4rem;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    padding: 50px 0 200px;
    .title {
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      color: $dark-gray-1;
    }
    .social-content {
      margin: 40px 0 24px;
      .img {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        overflow:hidden;
        img {
          display:block;
          width:100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          top: 50%; left: 50%;
          transform: translate(-50%,-50%);
        }
        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 40%;
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            #000000 100%
          );
          z-index: 10;
        }
        i {
          position: absolute;
          left: 20px;
          bottom: 20px;
          z-index: 11;
        }
      }
    }
    .card-content {
      margin-bottom: 30px;
      display: block;
      .card-box {
        padding: 20px 20px 32px;
        background-color: $light-gray-3;
        display: flex;
        flex-flow: column;
        .card-title {
          color: $dark-gray-2;
          font-size: 24px;
          line-height: 30px;
          font-weight: 700;
          order: 2;
          margin-top: 20px;
          @include ellipsis(3);
        }

        .date {
          font-size: 14px;
          font-weight: 500;
          line-height: 17px;
          color: $dark-gray-2;
          order: 1;
        }
      }
    }
  }
}
.panel {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.panel p {
  font-size: 6vw;
}

.red {
  background: red;
}
.blue {
  background: blue;
}
.green {
  background: green;
}
.orange {
  background: orange;
}
// -------------------------------- 소셜미디어 end
